import GroupDropdownMenu from "@features/Admin/Groups/Group/GroupDropdownMenu.tsx"
import GroupPageNavigation from "@features/Admin/Groups/Group/GroupPageNavigation.tsx"
import useGroupStore from "@features/Admin/Groups/stores/GroupStore.ts"

const GroupHeader = () => {
  const { name, kind } = useGroupStore((state) => state.group)
  return (
    <div className={"grid gap-2 grid-cols-3 w-full items-center"}>
      <div>
        <h2 className={"m-0"}>{name}</h2>
        <div className={"text-gray-400"}>{kind}</div>
      </div>
      <GroupPageNavigation />
      <div className={"flex flex-1 justify-end"}>
        <GroupDropdownMenu />
      </div>
    </div>
  )
}

export default GroupHeader
