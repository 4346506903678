import { FC, ReactElement } from "react"
import { useParams } from "react-router-dom"

import Card from "@components/Cards/Card"

import LinkCard from "@features/Feed/component/LinkCard"

import ContentCard from "./component/ContentCard"
import FeedErrorCard from "./component/FeedErrorCard.tsx"
import MoodSelector from "./component/MoodSelector"
import SurveyCard from "./component/SurveyCard"
import useFeedStore from "./stores/FeedStore"

const content_type_to_component = {
  MoodFeedItem: MoodSelector,
  SurveySet: SurveyCard,
  Post: ContentCard,
  Link: LinkCard,
}

interface FeedRendererProps {
  feedType?: "feed" | "dig_deeper"
}

const feedTitleMap = {
  feed: "Daily to-do",
  dig_deeper: "Dig Deeper",
}

const FeedRenderer: FC<FeedRendererProps> = ({ feedType = "feed" }) => {
  const { currentFeedResponse } = useFeedStore()
  const feedItems = currentFeedResponse?.items || []
  const filteredFeedItems = feedItems.filter((item) => item.section_key === feedType)
  const feedItemSectionTitle = feedTitleMap[feedType]
  const { date } = useParams()
  const currentActiveDate = date || new Date().toISOString().split("T")[0]

  const renderFeed = () => {
    const outerDivs: ReactElement[] = []
    let currentDiv: ReactElement[] = []
    let itemCount = 0

    filteredFeedItems.forEach((item: FeedItem) => {
      const Component = content_type_to_component[item.content_type] ?? FeedErrorCard
      const needsNewDiv = itemCount === 2 || (item.content_type === "MoodFeedItem" && itemCount > 0)

      if (needsNewDiv) {
        outerDivs.push(createOuterDiv(currentDiv))
        currentDiv = []
        itemCount = 0
      }

      currentDiv.push(
        <Component
          key={item.feed_item_id}
          feed_item_id={item.feed_item_id}
          feed_item_interaction_id={item.feed_item_interaction_id}
          completed_at={item.completed_at}
          content_id={item.content_id}
          content_type={item.content_type}
          name={item.name}
          section_key={item.section_key}
          item_payload={item.item_payload}
          time_end={item.time_end}
          occurrence_dtend={item.occurrence_dtend}
        />,
      )
      itemCount += item.content_type === "MoodFeedItem" ? 2 : 1

      if (itemCount > 1) {
        outerDivs.push(createOuterDiv(currentDiv))
        currentDiv = []
        itemCount = 0
      }
    })

    if (currentDiv.length) {
      outerDivs.push(createOuterDiv(currentDiv))
    }

    return outerDivs
  }

  const createOuterDiv = (divContent: ReactElement[]) => (
    <div key={divContent[0].key} className="flex flex-col justify-between outer-div gap-4">
      {divContent}
    </div>
  )

  return (
    <div>
      <div className="mt-8">
        <h2 className="font-light">{feedItemSectionTitle}</h2>
      </div>
      {filteredFeedItems.length === 0 ? (
        <Card additionalClasses="shadow-none" backgroundColor="neutral-50">
          <div className="text-center">Nothing for {currentActiveDate}.</div>
        </Card>
      ) : (
        <div className="w-full flex flex-row grid md:grid-cols-1 lg:grid-cols-2 gap-4 mb-4">{renderFeed()}</div>
      )}
    </div>
  )
}

export default FeedRenderer
