export const nameToColor = (name: string): string => {
  let hash = 0
  const randomSeed = 200
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash)
  }

  const random = (seed: number) => (Math.sin(seed) * randomSeed) % 1

  const r = Math.floor(155 + random(hash) * 100)
  const g = Math.floor(155 + random(hash + 1) * 100)
  const b = Math.floor(155 + random(hash + 2) * 100)

  return `rgb(${r}, ${g}, ${b})`
}

export const formatDate = (dateString: string): string => {
  const [year, month, day] = dateString.split("-").map(Number)
  const date = new Date(Date.UTC(year, month - 1, day))
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    weekday: "short",
    timeZone: "UTC",
  }

  const formatter = new Intl.DateTimeFormat("en-US", options)
  const parts = formatter.formatToParts(date)

  const monthPart = parts.find((part) => part.type === "month")?.value
  const dayPart = parts.find((part) => part.type === "day")?.value
  const weekdayPart = parts.find((part) => part.type === "weekday")?.value

  return `${monthPart} ${dayPart}, ${weekdayPart}`
}

export const formatLocalTime = (dateString: string, timeZone: string): string => {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
    timeZone,
  }

  return new Intl.DateTimeFormat("en-US", options).format(date)
}

export const surveyKeyToUpperCaseTitle = (surveyKey: string): string => {
  const allCapsKeys = [
    "BSL",
    "GAD",
    "PHQ",
    "PID",
    "SF",
    "DSM",
    "LEAS",
    "IIP",
    "SMS",
    "SH",
    "BOCS",
    "SD3",
    "SCS",
    "SCL",
    "UBQ",
    "SCID",
    "II",
    "SAQS",
    "RGPTS",
    "REF",
    "PER",
    "PDI",
    "PAI",
    "BOR",
    "MDQ",
    "MCNS",
    "IRI",
    "HSNS",
    "FDS",
    "DOCS",
    "CAGE",
    "BHPS",
    "BDI",
    "BAI",
    "AUT",
    "ADHD",
  ]

  let result = surveyKey.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())

  // Ensure specific keys are in uppercase
  for (const key of allCapsKeys) {
    const regex = new RegExp(`\\b${key}\\b`, "gi")
    result = result.replace(regex, key)
  }

  return result
}
