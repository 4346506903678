import { create } from "zustand"

interface ManageGroupsStore {
  groups: Group[]
  setGroups: (groups: Group[]) => void
}

const useManageGroupsStore = create<ManageGroupsStore>((set) => ({
  groups: [],
  setGroups: (groups: Group[]) => set({ groups }),
}))

export default useManageGroupsStore
