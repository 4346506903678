import { create } from "zustand"

interface MoodInsightStore {
  currentMoodResponse: InsightReportMoodData | null
  setCurrentMoodResponse: (response: InsightReportMoodData) => void
}

const useMoodInsightStore = create<MoodInsightStore>((set) => ({
  currentMoodResponse: null,
  setCurrentMoodResponse: (currentMoodResponse) => set({ currentMoodResponse }),
}))

export default useMoodInsightStore
