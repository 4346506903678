import ManageGroupsTable from "@features/Admin/Groups/ManageGroupsTable.tsx"
import ManageGroupsProvider from "@features/Admin/Groups/providers/ManageGroupsProvider.tsx"

const ManageGroups = () => {
  return (
    <ManageGroupsProvider>
      {/* @ts-expect-error refetch injected through cloneElement */}
      <ManageGroupsTable />
    </ManageGroupsProvider>
  )
}

export default ManageGroups
