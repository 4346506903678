import { ReactNode } from "react"

interface SurveyTitleButtonProps {
  onClick?: (...args: never[]) => void | Promise<void> | Promise<boolean>
  children: ReactNode
  isSelected?: boolean
}

const SurveyTitleButton = ({ onClick, children, isSelected = false }: SurveyTitleButtonProps) => {
  const classes = "text-black rounded-full px-3 py-1 m-1 border transition duration-300 ease-in-out"
  const selectedClasses = isSelected ? "bg-neutral-950 text-white shadow-xl" : ""

  return (
    <button onClick={onClick} type={"button"} className={`${classes} ${selectedClasses}`}>
      {children}
    </button>
  )
}

export default SurveyTitleButton
