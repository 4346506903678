import { ColumnDef } from "@tanstack/react-table"
import { useState } from "react"

import { Button } from "@/shadcn-components/ui/button.tsx"
import { Checkbox } from "@/shadcn-components/ui/checkbox.tsx"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shadcn-components/ui/dialog.tsx"

import UsersTable from "@components/Widgets/UsersTable/UsersTable.tsx"
import { UsersTableColumns } from "@components/Widgets/UsersTable/UsersTableColumns.tsx"

import api from "@utilities/api.ts"
import { success } from "@utilities/logger.ts"

interface Props {
  id: string
  name: string
  filteredIds: string[]
  refetch?: () => void
}

const AddUserToGroup = ({ id, name, filteredIds = [], refetch }: Props) => {
  const [selectedUsers, setSelectedUsers] = useState<ListUser[]>([])
  const [open, onOpenChange] = useState(false)
  const leftCustomColumns: ColumnDef<ListUser>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
  ]

  const handleSubmit = async () => {
    const userIds = selectedUsers.map((user) => user.user_id)
    const { data, status } = await api.post<{ message: string }>(`/groups/${id}/members`, { user_ids: userIds })

    if (status === 200) {
      success(data.message)
      onOpenChange(false)
      if (refetch) refetch()
    }
  }

  const columns = [...leftCustomColumns, ...UsersTableColumns.filter((c) => ["Display Name", "Email"].includes(c.id!))]

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger>
        <Button size={"sm"}>Add User</Button>
      </DialogTrigger>
      <DialogContent className={"min-w-[90%]"}>
        <DialogHeader>
          <DialogTitle>Add User to {name}</DialogTitle>
          <DialogDescription>Select a user below to immediately add them to {name}.</DialogDescription>
        </DialogHeader>
        <UsersTable title={""} columns={columns} setSelectedRows={setSelectedUsers} hiddenIds={filteredIds} />
        <DialogFooter>
          <Button disabled={selectedUsers.length === 0} onClick={() => void handleSubmit()}>
            Add to Group
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddUserToGroup
