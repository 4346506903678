import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
} from "@/shadcn-components/ui/dropdown-menu.tsx"

import ActionMenuTrigger from "@components/Menus/ActionMenu/ActionMenuTrigger.tsx"
import CopyRecordId from "@components/Menus/ActionMenu/CopyRecordId.tsx"

import api from "@utilities/api.ts"
import { success } from "@utilities/logger.ts"

interface Props {
  row: Row<Group & GroupListActions>
}

const ActionButtons = ({ row }: Props) => {
  const group = row.original

  const deleteGroup = async () => {
    const { group_id } = group

    if (group_id) {
      if (confirm("Are you sure you want to delete this group?")) {
        const response = await api.delete<{ message: string }>(`/groups/${group_id}`)
        if (response.status === 200) {
          // handle success and refresh this data
          success(response?.data?.message)
          row.original?.refetch()
        }
      }
    }
  }

  return (
    <>
      <DropdownMenu modal={false}>
        <ActionMenuTrigger />
        <DropdownMenuContent align={"end"}>
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <CopyRecordId value={group.group_id} />
          <DropdownMenuItem onClick={() => void deleteGroup()}>Delete Group</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

export default ActionButtons
