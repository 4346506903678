interface Props {
  data: AttendanceReport
}

const AttendanceListView = ({ data }: Props) => {
  if (!data?.checkins) return <div>No attendance data available</div>

  return (
    <div>
      {data?.checkins.map((client: AttendanceReportCheckin) => {
        return (
          <div className={"my-4 mx-2"}>
            <div className={"font-extrabold"}>{client?.name}</div>
            <div>
              {client?.dates_with_responses.map(({ value, date }) => {
                if (value === "Yes") return <li className={"ml-2"}>{date} ✅</li>
                if (value === "No") return <li className={"ml-2"}>{date} ❌</li>
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AttendanceListView
