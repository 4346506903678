import { useQuery } from "@tanstack/react-query"
import { ColumnDef } from "@tanstack/react-table"
import { Dispatch, ReactElement, ReactNode, SetStateAction, cloneElement } from "react"

import { DataTable } from "@components/Tables/DataTable.tsx"
import { UsersTableColumns } from "@components/Widgets/UsersTable/UsersTableColumns.tsx"

import api from "@utilities/api.ts"

interface Props {
  title?: string
  actionButtons?: ReactNode[]
  columns?: ColumnDef<ListUser>[]
  setSelectedRows?: Dispatch<SetStateAction<ListUser[]>>
  hiddenIds?: string[]
  endpoint?: string
}

const UsersTable = ({
  title = "All Users",
  actionButtons = [],
  columns = UsersTableColumns,
  setSelectedRows,
  hiddenIds = [],
  endpoint = "/admin/users",
}: Props) => {
  const { data, refetch, isPending, isError, error } = useQuery<ListUser[]>({
    retry: false,
    queryKey: ["users", endpoint],
    queryFn: async () => {
      const response = await api.get<ListUser[]>(endpoint)
      return response.data
    },
  })

  if (isPending) return <div>Loading...</div>
  if (isError) return <div>Error: {error.message}</div>

  // const ActionButtons = [<AddUserDialog />]
  const ActionButtons = actionButtons.filter(Boolean).map((a) => cloneElement(a as ReactElement, { refetch }))
  const transformedUsers = data
    .map((user) => ({ ...user, refetch })) // insert refetch into each row
    .filter((user) => !hiddenIds.includes(user.user_id)) // filter out hidden users

  return (
    <DataTable
      title={title}
      columns={columns}
      data={transformedUsers}
      ActionButtonsSection={ActionButtons}
      setSelectedRows={setSelectedRows}
    />
  )
}

export default UsersTable
