import { create } from "zustand"

interface HomeTimeSummaryStore {
  currentHomeTimeResponse: HomeTimeSummaryResponse | null
  setCurrentHomeTimeResponse: (response: HomeTimeSummaryResponse) => void
}

const useHomeTimeSummaryStore = create<HomeTimeSummaryStore>((set) => ({
  currentHomeTimeResponse: null,
  setCurrentHomeTimeResponse: (currentHomeTimeResponse) => set({ currentHomeTimeResponse }),
}))

export default useHomeTimeSummaryStore
