import { formatDate, formatLocalTime } from "../../utils/utils"

interface MoodInsightAnswerListProps {
  data: InsightReportMoodData | null
}

const MoodInsightAnswerList: React.FC<MoodInsightAnswerListProps> = ({ data }) => {
  if (!data) {
    return <p>No mood data available.</p>
  }
  const formattedData = Object.entries(data).map(([date, moods]) => ({
    date,
    moods: moods.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()),
  }))

  const moodRowSyle =
    "w-full px-3 py-2 flex justify-between items-center last:rounded-b-lg last:border-b \
    first:rounded-t-lg first:border-t border-x not-last:border-b border-neutral-100"

  return (
    <div className="flex flex-col items-center w-full mt-4">
      <div className="w-full">
        {formattedData.map(({ date, moods }) => (
          <div key={date} className="flex flex-col not-last:mb-6 w-full">
            <div className="flex mb-2">{formatDate(date)}</div>
            <div className="flex w-full flex-col">
              {moods.length > 0 ? (
                moods.map((mood, index) => (
                  <div key={index} className={moodRowSyle} style={{ backgroundColor: `${mood.color}33` }}>
                    <div className="flex flex-row justify-between w-full">
                      <div className="flex flex-row gap-2 items-center">
                        <div className="text-sm text-neutral-500 w-10 rounded-full bg-white text-center">
                          {mood.intensity !== null ? `${Math.floor(mood.intensity * 10)}` : "N/A"}
                        </div>
                        <div className="font-medium w-24">{mood.mood_name}</div>
                      </div>
                      <div className={`text-sm ${mood.answer ? "text-black" : "text-gray-500 italic"}`}>
                        {mood.answer || "No answer provided"}
                      </div>
                    </div>
                    <div>
                      <div className="w-8 px-1 text-xs text-neutral-300 ml-3">
                        {formatLocalTime(mood.created_at, mood.completed_tz)}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-neutral-300 text-sm italic">No mood data for this day.</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MoodInsightAnswerList
