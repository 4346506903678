import { useEffect, useRef } from "react"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import ChoicesHistoryD3 from "../../graph/D3Files/ChoicesHistoryD3.js"
import QuestionHeader from "./QuestionHeader.tsx"

interface ChoicesHistoryProps {
  indexString?: string
  graphData?: SurveyInsightQuestionHistory
}

const ChoicesHistory = ({ graphData, indexString = "Q" }: ChoicesHistoryProps) => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const currentGraphData = graphData
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0

  useEffect(() => {
    if (isValidSize) {
      new ChoicesHistoryD3(chartArea.current, containerSize.width, containerSize.height, currentGraphData)
    }
  }, [containerSize, graphData, currentGraphData, isValidSize])

  const calcualteHeightBasedOnChoices = () => {
    const lenghth = graphData?.choices?.length
    if (!lenghth) return "h-60"
    if (lenghth < 4) return "h-60"
    if (lenghth < 6) return "h-72"
    if (lenghth < 8) return "h-80"
    if (lenghth < 10) return "h-96"
    return "h-160"
  }

  return (
    <div className="w-full rounded-xl flex flex-col mb-6">
      <QuestionHeader
        indexString={indexString}
        questionText={graphData?.question_text}
        questionType={graphData?.question_type}
      />
      <div className="w-full h-auto overflow-hidden" ref={containerRef}>
        <div
          className={`chart-area flex-1 w-full overflow-hidden ${calcualteHeightBasedOnChoices()}`}
          ref={chartArea}
        ></div>
      </div>
    </div>
  )
}

export default ChoicesHistory
