import { faBuilding, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Card from "@/components/Cards/Card"
import FormattedAddress from "@/components/DataFormatters/FormattedAddress"
import FormattedPhoneNumber from "@/components/DataFormatters/FormattedPhoneNumber"
import { Avatar, AvatarFallback, AvatarImage } from "@/shadcn-components/ui/avatar"

import useClinicianProfileStore from "../stores/ClinicianProfileStore"

const ClinicianProfileBody = () => {
  const clinician = useClinicianProfileStore((state) => state.clinician)
  const clinicianInitials = clinician ? `${clinician.first_name[0]}${clinician.last_name[0]}` : ""
  return (
    <div>
      <Card>
        {/* GroupOverview */}
        <div className={"flex gap-4"}>
          <Avatar>
            <AvatarImage src={clinician?.image_url} alt="Profile" />
            <AvatarFallback className={"text-lg"}>{clinicianInitials}</AvatarFallback>
          </Avatar>
          <h1>
            {clinician?.first_name} {clinician?.last_name}
          </h1>
        </div>
        {/* Contact Information */}
        <div className={"ml-12"}>
          <div>
            <FontAwesomeIcon className={"w-6"} icon={faPhone} />
            <FormattedPhoneNumber phoneNumber={clinician?.phone ?? ""} />
            {!clinician?.phone && "No phone number provided"}
          </div>
          <div>
            <FontAwesomeIcon className={"w-6"} icon={faBuilding} />
            <FormattedAddress
              address={{
                address_1: clinician?.address_1 ?? "",
                address_2: clinician?.address_2 ?? "",
                address_3: clinician?.address_3 ?? "",
                city: clinician?.city ?? "",
                state: clinician?.state ?? "",
                postal_code: clinician?.postal_code ?? "",
                country: clinician?.country ?? "",
              }}
            />
            {!clinician?.city && "No address provided"}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ClinicianProfileBody
