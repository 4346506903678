import { useNavigate, useParams } from "react-router-dom"

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem } from "@/shadcn-components/ui/dropdown-menu.tsx"

import ActionMenuTrigger from "@components/Menus/ActionMenu/ActionMenuTrigger.tsx"

import api from "@utilities/api.ts"
import { success } from "@utilities/logger.ts"

const GroupDropdownMenu = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const deleteGroup = async () => {
    if (id) {
      if (confirm("Are you sure you want to delete this group?")) {
        const response = await api.delete<{ message: string }>(`/groups/${id}`)
        if (response.status === 200) {
          // handle success and refresh this data
          success(response?.data?.message)
          navigate("/groups")
        }
      }
    }
  }
  return (
    <>
      <DropdownMenu modal={false}>
        <ActionMenuTrigger />
        <DropdownMenuContent align={"end"}>
          <DropdownMenuItem onClick={() => void deleteGroup()}>Delete Group</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

export default GroupDropdownMenu
