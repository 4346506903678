import { create } from "zustand"

interface SurveysInsightStore {
  currentSurveysInsight: SurveysInsightResponse | null
  setCurrentSurveysInsight: (currentSurveysInsight: SurveysInsightResponse) => void
}

const useSurveysInsightStore = create<SurveysInsightStore>((set) => ({
  currentSurveysInsight: null,
  setCurrentSurveysInsight: (currentSurveysInsight) => set({ currentSurveysInsight }),
}))

export default useSurveysInsightStore
