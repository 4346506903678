import AcceptInvitationContainer from "@/core/Invitations/components/AcceptInvitationContainer.tsx"
import Logo from "@/core/Layout/Logo.tsx"

const DEFAULT_INVITATION_ERROR_MESSAGE =
  "An error occurred while trying to retrieve this invitation. It may no longer be valid or you may be logged into the wrong account." +
  " Please try refreshing the page. You may also want to ask the user who invited you to send you a new invitation."

const AcceptInvitationError = ({ message = DEFAULT_INVITATION_ERROR_MESSAGE }) => {
  // todo rjl 2024-08-26: style me
  const isClinicianVerificationError = message.includes("User account is pending verification.")

  return (
    <div className={"flex justify-center items-center place-content-center"}>
      <AcceptInvitationContainer>
        <div className={"p-8"}>
          <div className={"mb-4"}>
            <Logo />
          </div>
          <div className={"text-2xl mb-2"}>
            {isClinicianVerificationError ? "Account Verification Required" : "An error has occurred"}
          </div>
          {message}
        </div>
      </AcceptInvitationContainer>
    </div>
  )
}

export default AcceptInvitationError
