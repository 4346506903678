import ActivitiesSummarySection from "./ActivitiesSummarySection"
import SurveysSummarySection from "./SurveysSummarySection"

const SummaryPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Summary</h3>
      </div>

      <ActivitiesSummarySection />
      <SurveysSummarySection />
    </div>
  )
}

export default SummaryPage
