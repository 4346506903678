import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { useEffect, useState } from "react"

import { Button } from "@/shadcn-components/ui/button.tsx"
import { Table, TableCell, TableHeader } from "@/shadcn-components/ui/table.tsx"

import AttendanceClientRow from "@features/Attendance/AttendanceClientRow.tsx"

interface Props {
  data: AttendanceReport
}

const AttendanceTableView = ({ data }: Props) => {
  const [page, setPage] = useState(1)

  const pagination = {
    pageWidth: 10, // 1 week per page
    pageCount: Math.ceil(data?.dates.length / 10),
    offsetBegin: page * 10 - 10,
    offsetEnd: page * 10,
  }

  useEffect(() => {
    setPage(pagination.pageCount)
  }, [data?.dates, pagination.pageCount])

  return (
    <>
      <Table>
        <TableHeader>
          <TableCell></TableCell>
          {data?.dates.slice(pagination.offsetBegin, pagination.offsetEnd).map((date) => {
            const zonedDate = utcToZonedTime(new Date(date), "UTC")
            return (
              <TableCell className={"text-center"}>
                <div className={"font-extrabold"}>{format(zonedDate, "iii")}</div>
                <div className={"text-gray-500"}>{format(zonedDate, "MM/dd")}</div>
              </TableCell>
            )
          })}
        </TableHeader>
        {data?.checkins.map((client) => (
          <AttendanceClientRow {...client} offsetBegin={pagination.offsetBegin} offsetEnd={pagination.offsetEnd} />
        ))}
      </Table>
      <div className={"flex items-center justify-end gap-2 mt-4"}>
        <Button size={"sm"} onClick={() => setPage(page - 1)} disabled={page === 1}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <div>
          {page} / {pagination.pageCount}
        </div>
        <Button size={"sm"} onClick={() => setPage(page + 1)} disabled={page === pagination.pageCount}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </div>
    </>
  )
}

export default AttendanceTableView
