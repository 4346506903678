import { useQuery } from "@tanstack/react-query"
import { ReactElement, cloneElement, useEffect } from "react"
import { useParams } from "react-router-dom"

import useGroupStore from "@features/Admin/Groups/stores/GroupStore.ts"

import api from "@utilities/api.ts"

const GroupProvider = ({ children }: ProviderBaseProps) => {
  const { id } = useParams()
  const setGroup = useGroupStore((state) => state.setGroup)

  const { data, isPending, isError, error, refetch } = useQuery({
    queryKey: ["group", id],
    retry: false,
    queryFn: async () => {
      const response = await api.get<Group & { message: string }>(`/groups/${id}`)
      if (response.status === 200) return response.data
      if (response.status === 403) throw new Error(response.data.message)
    },
  })

  useEffect(() => {
    if (data) setGroup(data)
  }, [data, setGroup])

  if (isPending) return <div>Loading...</div>
  if (isError) return <div>Error: {error.message}</div>

  return children ? cloneElement(children as ReactElement, { refetch }) : null
}

export default GroupProvider
