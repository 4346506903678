import { FC } from "react"

import FeedItemCardButtons from "./Atoms/FeedItemCardButtons"
import FeedItemCardContent from "./Atoms/FeedItemCardContent"
import GradientBackground from "./Atoms/GradientBackground"

interface SurveyCardProps {
  onClick?: () => void
  disabled?: boolean
  description?: string
  buttonLabel?: string
  time_end: string | null
  occurrence_dtend: string | null
}

const SurveyCard: FC<SurveyCardProps & FeedItemComponent> = ({
  onClick,
  disabled = false,
  feed_item_interaction_id = "",
  name = "Card name",
  description = "",
  buttonLabel = "Start",
  completed_at,
  time_end,
  occurrence_dtend, // gives date
}) => {
  // Classes for the container of the card
  const containerClasses = `flex flex-col w-full items-stretch bg-neutral-50 rounded-lg text-black justify-between relative overflow-hidden`

  // Decide on size class
  const sizeClasses = "h-48"
  const feedItemType = "Survey"
  const completed = completed_at !== null

  const url = `/p/feed/survey/${feed_item_interaction_id}`

  const pastDue = time_end && new Date(`${occurrence_dtend}T${time_end}`) < new Date()
  const getButtonLabel = () => {
    if (completed) return "Completed"
    if (pastDue) return "Past due"

    if (time_end)
      return (
        "Due by " +
        new Date(`${occurrence_dtend}T${time_end}`).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      )
    return buttonLabel
  }

  return (
    <div className={`${containerClasses} ${sizeClasses}`} onClick={onClick} role="button" aria-disabled={disabled}>
      <GradientBackground bgLeft="bg-red-400" bgRight="bg-purple-400" />
      <FeedItemCardContent feedItemType={feedItemType} name={name} description={description} />
      <FeedItemCardButtons
        completed={completed}
        disabled={pastDue || disabled}
        url={url}
        buttonLabel={getButtonLabel()}
      />
    </div>
  )
}

export default SurveyCard
