import { useState } from "react"

import { Card } from "@/shadcn-components/ui/card.tsx"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"
import ViewSelector from "@components/Views/ViewSelector.tsx"

import AttendanceListView from "@features/Attendance/AttendanceListView.tsx"
import AttendanceTableView from "@features/Attendance/AttendanceTableView.tsx"
import useAttendanceChart from "@features/Attendance/hooks/useAttendanceChart.ts"

interface Props {
  groupId: string
}

const AttendanceChart = ({ groupId }: Props) => {
  const { data, isLoading } = useAttendanceChart({ groupId })
  const [viewType, setViewType] = useState<string>("grid")
  if (isLoading) return <LoadingSpinner />
  if (!data || data?.dates?.length === 0)
    return <Card className={"p-4 w-full"}>No attendance data found. Do you have any clients in this group?</Card>
  return (
    <Card className={"p-4 w-full"}>
      <div className={"flex justify-between flex-1 items-center"}>
        <div>
          <h3 className={"mb-0"}>Attendance Chart</h3>
          <div className={"text-gray-500"}>Previous 90 days</div>
        </div>
        <div>
          <ViewSelector selected={viewType} setSelected={setViewType} supportedViews={["grid", "list"]} />
        </div>
      </div>
      {viewType === "grid" && <AttendanceTableView data={data} />}
      {viewType === "list" && <AttendanceListView data={data} />}
    </Card>
  )
}

export default AttendanceChart
