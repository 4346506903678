import { useQuery, useQueryClient } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import Card from "@components/Cards/Card.tsx"

import api from "@utilities/api.ts"
import { log } from "@utilities/logger"

import useInsightsGraphStore from "../stores/InsightsGraphStore"

const InsightsGraphProvider = ({ children }: { children: ReactNode }) => {
  const setInsightsGraphResponse = useInsightsGraphStore((state) => state.setCurrentGraphResponse)
  const setIsLoading = useInsightsGraphStore((state) => state.setIsLoading)
  const currentActiveTab: GraphTabType = useInsightsGraphStore((state) => state.activeTab)
  const queryClient = useQueryClient()

  const { clientId } = useParams<{ clientId: string }>()

  const {
    data,
    isSuccess,
    isLoading: loading,
    isError,
  } = useQuery({
    queryKey: ["insightsGraphData", currentActiveTab, clientId],
    retry: false,
    queryFn: async () => {
      log(`Fetching insights graph data for ${currentActiveTab}`)
      const url = clientId
        ? `/dashboard/charts/${clientId}/${currentActiveTab}`
        : `/dashboard/charts/self/${currentActiveTab}`
      const response = await api.get<APIInsightsGraphResponse>(url)

      // const response = await api.get<APIInsightsGraphResponse>(`/dashboard/charts/self/${currentActiveTab}`)
      if (response.status === 200) {
        return response.data
      } else throw new Error("Fetching insights graph data failed")
    },
  })

  useEffect(() => {
    if (data) {
      // TODO(05/14/24): This is a temporary fix till the API is updated
      setInsightsGraphResponse(data.data)
    }
  }, [data, isSuccess, setInsightsGraphResponse])

  useEffect(() => {
    if (isSuccess) void queryClient.invalidateQueries({ queryKey: ["insightsGraphData"] })
    setIsLoading(loading)
  }, [isSuccess, currentActiveTab, queryClient, loading, setIsLoading])

  if (isError) {
    return (
      <Card additionalClasses="shadow-none" backgroundColor="neutral-50">
        <div className="text-center">Failed to load graph</div>
      </Card>
    )
  }

  return children
}

export default InsightsGraphProvider
