import * as d3 from "d3"

class NumericalHistoryD3 {
  constructor(element, width, height, data) {
    this.element = element
    this.width = width
    this.height = height
    this.data = data
    this.margin = { top: 15, right: 0, bottom: 30, left: 40 }
    this.svg = null

    this.createChart()
  }

  createChart() {
    d3.select(this.element).select("svg").remove()

    if (!this.data || !this.data.responses || this.data.responses.length === 0) {
      return
    }

    const svg = d3.select(this.element).append("svg").attr("width", this.width).attr("height", this.height)

    const chartGroup = svg.append("g").attr("transform", `translate(${this.margin.left},${this.margin.top})`)

    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom

    // Parse dates and prepare data
    const parseDate = d3.timeParse("%Y-%m-%d")
    const responses = this.data.responses.map((d) => ({
      date: parseDate(d.date),
      values: d.values,
    }))

    // Flatten the responses for multiple values
    const flattenedData = responses.flatMap((response) =>
      response.values.map((value) => ({
        date: response.date,
        value: +value,
      })),
    )

    // Sort the data by date for proper line drawing
    flattenedData.sort((a, b) => new Date(a.date) - new Date(b.date))

    // Extract unique dates
    const uniqueDates = [...new Set(flattenedData.map((d) => d.date))]

    // Define scales
    const xScale = d3.scaleBand().domain(uniqueDates).range([0, innerWidth]).padding(0.2)

    const yScale = d3
      .scaleLinear()
      .domain([+this.data.choices[0], +this.data.choices[1]])
      .range([innerHeight, 0])
      .nice()

    // Draw horizontal grid lines for y-axis
    chartGroup
      .selectAll(".y-grid-line")
      .data(yScale.ticks())
      .enter()
      .append("line")
      .attr("class", "y-grid-line")
      .attr("x1", 0)
      .attr("x2", innerWidth)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "4")

    // Draw vertical grid lines for x-axis
    chartGroup
      .selectAll(".x-grid-line")
      .data(uniqueDates)
      .enter()
      .append("line")
      .attr("class", "x-grid-line")
      .attr("x1", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("x2", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("y1", 0)
      .attr("y2", innerHeight)
      .attr("stroke", "#ccc")
      .attr("opacity", 0.4)
      .attr("stroke-dasharray", "2")

    // Draw axes without lines and ticks
    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat((d) => d3.timeFormat("%m/%d")(new Date(d)))
      .tickSize(0)

    const yAxis = d3.axisLeft(yScale).tickSize(0)

    chartGroup
      .append("g")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(xAxis)
      .selectAll("path, .tick line")
      .remove()
      .style("font-size", "10px")

    chartGroup.selectAll(".tick text").style("font-size", "10px").attr("dy", "15px")

    const yAxisGroup = chartGroup.append("g").call(yAxis)
    yAxisGroup.selectAll("path, .tick line").remove()
    yAxisGroup.selectAll(".tick text").style("font-size", "10px")

    // Draw line connecting the dots
    const lineGenerator = d3
      .line()
      .x((d) => xScale(d.date) + xScale.bandwidth() / 2)
      .y((d) => yScale(d.value))
      .curve(d3.curveMonotoneX) // Smooth the line

    chartGroup
      .append("path")
      .datum(flattenedData)
      .attr("class", "line")
      .attr("d", lineGenerator)
      .attr("fill", "none")
      .attr("stroke", "#000000")
      .attr("stroke-width", 2)
      .style("opacity", 0.4)

    // Draw dots for responses
    chartGroup
      .selectAll("circle")
      .data(flattenedData)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.date) + xScale.bandwidth() / 2)
      .attr("cy", (d) => yScale(d.value))
      .attr("r", 6)
      .attr("fill", "#000000")

    // Show numbers above the dots
    chartGroup
      .selectAll(".dot-label")
      .data(flattenedData)
      .enter()
      .append("text")
      .attr("x", (d) => xScale(d.date) + xScale.bandwidth() / 2)
      .attr("y", (d) => yScale(d.value) - 10)
      .attr("text-anchor", "middle")
      .style("font-size", "10px")
      .text((d) => d.value)
  }
}

export default NumericalHistoryD3
