import { useNavigate } from "react-router-dom"

import { Tabs, TabsList, TabsTrigger } from "@/shadcn-components/ui/tabs.tsx"

const GroupPageNavigation = () => {
  const navigate = useNavigate()

  const setPage = (value: string) => navigate(value)
  const getPage = () => window.location.pathname.split("/")?.pop()

  const tabClasses =
    "bg-neutral-50 rounded-full text-neutral-400 data-[state=active]:bg-black data-[state=active]:text-white focus-visible:ring-0"
  const tabWrapperClasses = "bg-neutral-50 rounded-full"
  return (
    <Tabs className={"flex justify-center"} onValueChange={setPage} value={getPage()}>
      <TabsList className={tabWrapperClasses}>
        <TabsTrigger className={tabClasses} value={"overview"}>
          Overview
        </TabsTrigger>
        <TabsTrigger className={tabClasses} value={"members"}>
          Members
        </TabsTrigger>
      </TabsList>
    </Tabs>
  )
}

export default GroupPageNavigation
