const QuestionHeader = ({
  indexString,
  questionText,
  questionType,
}: {
  indexString: string
  questionText?: string
  questionType?: string
}) => (
  <div className="flex flex-row items-start">
    <div className="text-center w-8 h-6 mr-1 bg-neutral-950 text-white rounded-lg mt-2">{indexString}</div>

    <div className="flex m-2 rounded-lg w-full">{questionText}</div>

    <div className="text-center text-xs px-3 mr-1 py-1 border text-neutral-300 rounded-full mt-2 whitespace-nowrap">
      {questionType?.replace("_", " ")}
    </div>
  </div>
)

export default QuestionHeader
