import { Link } from "react-router-dom"

interface CalendarDateProps {
  date?: string
  emotion?: string
  isSelect?: boolean
}

const CalendarDate = ({ date = new Date().toString(), emotion = "", isSelect = true }: CalendarDateProps) => {
  const CalendarButton = ({
    isFutureDate,
    dayOfWeek,
    dayOfMonth,
  }: {
    isFutureDate: boolean
    dayOfWeek: string
    dayOfMonth: string
  }) => {
    const borderColor = isSelect ? "border-white" : "border-black"
    const emotionColor = emotion ? `bg-${emotion.toLowerCase()}-200` : `border ${borderColor} border-dotted`

    const weekDayTextColor = isSelect ? "text-white" : "text-black"
    const dateTextColor = isSelect ? "text-neutral-200" : "text-neutral-400"
    const bgColor = isSelect ? "bg-black" : "bg-white"

    return (
      <Link to={`/${date}`}>
        <button
          className={`h-18 w-20 flex-col items-center justify-center ${bgColor} border-2 border-white hover:border-neutral-50 rounded-lg p-2`}
          disabled={isFutureDate}
        >
          <div className={`${emotionColor} h-2 w-2 rounded-full m-auto`}></div>
          <div className={`text-lg px-2 ${dateTextColor}`}>{dayOfWeek}</div>
          <div className={`${weekDayTextColor}`}>{dayOfMonth}</div>
        </button>
      </Link>
    )
  }

  // handle case where the date selected is "now"
  if (date === "now") {
    const today = new Date()

    return <CalendarButton isFutureDate={false} dayOfWeek={"Today"} dayOfMonth={today.getDate().toString()} />
  }

  const year = parseInt(date.toString().substring(0, 4), 10)
  const month = parseInt(date.toString().substring(5, 7), 10) - 1 // Month is zero-based
  const day = parseInt(date.toString().substring(8, 10), 10)

  const dateObj = new Date(year, month, day)
  const currentTime = new Date()
  const isFutureDate = dateObj > currentTime

  const dayOfWeek = dateObj.toLocaleDateString("en-US", { weekday: "short" })
  const dayOfMonth = dateObj.getDate()

  return <CalendarButton isFutureDate={isFutureDate} dayOfWeek={dayOfWeek} dayOfMonth={dayOfMonth.toString()} />
}

export default CalendarDate
