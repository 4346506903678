import { DropdownMenu, DropdownMenuContent, DropdownMenuItem } from "@/shadcn-components/ui/dropdown-menu.tsx"

import ActionMenuTrigger from "@components/Menus/ActionMenu/ActionMenuTrigger.tsx"

import api from "@utilities/api.ts"
import { error, success } from "@utilities/logger.ts"

interface Props {
  data: ListUser
  groupId: string
}

const GroupMemberDropdown = ({ data, groupId }: Props) => {
  const deleteUserFromGroup = async () => {
    const response = await api.delete<{ message: string }>(`/groups/${groupId}/members/${data?.user_id}`)
    if (response.status === 200) {
      if (data?.refetch) data.refetch()
      success(response.data.message)
    } else {
      error(response.data.message)
    }
  }

  const makeUserAdmin = async () => {
    const response = await api.put<{
      message: string
    }>(`/groups/${groupId}/members/${data?.user_id}`, { roles: ["admin"] })
    if (response.status === 200) {
      if (data?.refetch) data.refetch()
      success(response.data.message)
    } else {
      error(response.data.message)
    }
  }

  return (
    <DropdownMenu modal={false}>
      <ActionMenuTrigger />
      <DropdownMenuContent align={"end"}>
        <DropdownMenuItem onClick={() => void makeUserAdmin()}>Make admin</DropdownMenuItem>
        <DropdownMenuItem onClick={() => void deleteUserFromGroup()}>Remove from group</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default GroupMemberDropdown
