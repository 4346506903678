const GroupSettings = () => {
  return (
    <div>
      <div>Onboarding Configuration</div>
      <div></div>
    </div>
  )
}

export default GroupSettings
