import { DropdownMenuItem } from "@/shadcn-components/ui/dropdown-menu.tsx"

import { success } from "@utilities/logger.ts"

interface Props {
  value: string
  label?: string
}

const CopyRecordId = ({ value, label = "Copy ID" }: Props) => {
  const copyId = async () => {
    await navigator.clipboard.writeText(value)
    success("ID copied to clipboard")
  }

  return <DropdownMenuItem onClick={() => void copyId()}>{label}</DropdownMenuItem>
}

export default CopyRecordId
