import { useEffect, useMemo, useState } from "react"

import SurveyButtonGroup from "../survey/SurveyButtonGroup.tsx"
import SurveyDetails from "../survey/SurveyDetails.tsx"
import useSurveysInsightStore from "../survey/store/SurveysInsightStore.ts"

const HabitsInsightView: React.FC = () => {
  const cardStyle = "border w-full shadow-none"
  const currentSurveysInsight = useSurveysInsightStore((state) => state.currentSurveysInsight)

  const surveyKeys = useMemo(() => {
    return currentSurveysInsight
      ? Object.keys(currentSurveysInsight.survey_detail_data).filter((key) => key.includes("habit"))
      : []
  }, [currentSurveysInsight])

  const [selectedSurveyKey, setSelectedSurveyKey] = useState<string>(surveyKeys[0] || "")

  const handleClickedSurvey = (surveyKey: string) => {
    setSelectedSurveyKey(surveyKey)
  }

  useEffect(() => {
    // Set the first survey key as selected survey key if no survey key is selected
    if (surveyKeys.length > 0 && !selectedSurveyKey) {
      setSelectedSurveyKey(surveyKeys[0])
    }
  }, [surveyKeys, selectedSurveyKey])

  const selectedSurveyData = currentSurveysInsight ? currentSurveysInsight.survey_detail_data[selectedSurveyKey] : null

  if (!currentSurveysInsight?.survey_detail_data) {
    return <div>Could not load surveys insights at this time</div>
  }

  return (
    <div className="w-full flex flex-col gap-4">
      <SurveyButtonGroup
        surveyKeys={surveyKeys}
        selectedSurveyKey={selectedSurveyKey}
        onSurveyClick={handleClickedSurvey}
      />
      <SurveyDetails selectedSurveyData={selectedSurveyData} cardStyle={cardStyle} />
    </div>
  )
}

export default HabitsInsightView
