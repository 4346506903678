import { useState } from "react"
import { z } from "zod"

import { GroupTypes } from "@/data/groups.const.ts"
import { Button } from "@/shadcn-components/ui/button.tsx"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shadcn-components/ui/dialog.tsx"

import FormBuilderV2, { FormField, zodFormSchemaBuilder } from "@components/Forms/FormBuilderV2.tsx"

import api from "@utilities/api.ts"
import { success } from "@utilities/logger.ts"

interface AddGroupProps {
  onReload: () => void
}

const AddGroup = ({ onReload }: AddGroupProps) => {
  const [open, setOpen] = useState(false)

  const formFields: FormField[] = [
    {
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "IOP Group 3",
      zSchema: z
        .string()
        .min(2, {
          message: "Must be at least 2 characters long",
        })
        .max(32, { message: "Cannot be longer than 32 characters" }),
    },
    {
      label: "Description",
      name: "description",
      type: "textarea",
      placeholder: "...",
      zSchema: z.string(),
    },
    {
      label: "Type",
      name: "kind",
      type: "select",
      zSchema: z.enum(GroupTypes as unknown as [string, ...string[]]),
    },
  ]

  const defaultValues: {
    name: string
    description: string
  } = {
    name: "",
    description: "",
  }

  const formSchema = zodFormSchemaBuilder(formFields)

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    const response = await api.post("/groups", values)

    if (response.status === 200) {
      success("Group created successfully")
      setOpen(false)
      onReload()
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button size={"sm"}>Add Group</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Group</DialogTitle>
        </DialogHeader>
        <FormBuilderV2 formFields={formFields} onSubmit={(v) => void handleSubmit(v)} defaultValues={defaultValues}>
          <DialogFooter>
            <Button type={"submit"}>Create</Button>
          </DialogFooter>
        </FormBuilderV2>
      </DialogContent>
    </Dialog>
  )
}

export default AddGroup
