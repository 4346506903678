import { create } from "zustand"

interface GroupStore {
  group: Group
  setGroup: (group: Group) => void
}

const useGroupStore = create<GroupStore>((set) => ({
  group: {
    group_id: "",
    name: "",
    description: "",
    kind: "",
    owner: {
      user_id: "",
      email: "",
      name: "",
    },
    member_count: 0,
    members: [],
  } as Group,
  setGroup: (group: Group) => set({ group }),
}))

export default useGroupStore
