import { faUser, faUserDoctor } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import BaseButton from "@/components/Buttons/BaseButton"
import SignupContainer from "@/core/Authentication/components/Signup/Molecules/SignupContainer.tsx"

import CardButton from "@components/Buttons/CardButton.tsx"
import CardButtonContainer from "@components/Containers/CardButtonContainer.tsx"

const SignupExperienceSelection = () => {
  const [params] = useSearchParams()
  const product = params.get("product")

  useEffect(() => {
    if (product) {
      localStorage.setItem("chosen-subscription-plan-key", product)
    }
  }, [product])
  const navigate = useNavigate()
  // this will be shown when the user is signing up for an organization
  const initialExperience = localStorage.getItem("selected-experience")
  const [selectedExperience, setSelectedExperience] = useState(initialExperience)

  useEffect(() => {
    if (!selectedExperience) return
    localStorage.setItem("selected-experience", selectedExperience)
  }, [selectedExperience])

  const experiences = [
    <CardButton
      selected={selectedExperience === "hospital"}
      onClick={() => setSelectedExperience("hospital")}
      title={"I'm receiving care"}
      description={""}
      icon={faUser}
      fullWidthImage={true}
    />,
    <CardButton
      selected={selectedExperience === "clinician"}
      onClick={() => setSelectedExperience("clinician")}
      title={"I'm providing care"}
      description={""}
      icon={faUserDoctor}
      fullWidthImage={true}
    />,
  ]

  const continueDisabled = !selectedExperience
  const handleContinue = () => {
    if (!selectedExperience) return

    if (selectedExperience === "hospital") {
      navigate("/register/organization")
    } else if (selectedExperience === "individual") {
      navigate("/register/create-account")
    } else if (selectedExperience === "clinician") {
      navigate("/register/clinician")
    }
  }

  return (
    <SignupContainer>
      <h1 className={"text-2xl"}>Welcome to Personily</h1>
      <p className={"text-gray-500"}>
        Before we can continue, we need to know if you're a client receiving care or a healthcare provider.
      </p>
      <CardButtonContainer>{experiences}</CardButtonContainer>
      <div className={"w-full"}>
        <BaseButton onClick={handleContinue} disabled={continueDisabled} color={"black"} fullWidth>
          Continue
        </BaseButton>
      </div>
    </SignupContainer>
  )
}

export default SignupExperienceSelection
