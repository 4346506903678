import Card from "@/components/Cards/Card.tsx"

import SectionContainer from "../atom/SectionContainer"
import ActivityGraphSummaryCard from "../summary/ActivityGraphSummaryCard"
import HomeTimeSummaryProvider from "../summary/provider/HomeTimeSummaryProvider"
import useHomeTimeSummaryStore from "../summary/store/HomeTimeSummaryStore"
import LocationStackChart from "./LocationStackChart"
import LocationInsightProvider from "./provider/LocationInsightProvider"

const LocationSection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"

  const { currentHomeTimeResponse } = useHomeTimeSummaryStore()
  const homeTimeUnit = currentHomeTimeResponse?.unit || ""
  const homeTimeValue = currentHomeTimeResponse?.average_home_time || "--"
  const homeTimeTrend = currentHomeTimeResponse?.trend || "none"
  const homeTimeChartData = currentHomeTimeResponse?.chart_data || []

  return (
    <SectionContainer title="Location" description="Time is adjusted to the client's timezone">
      <div className="flex gap-3 flex-col">
        <HomeTimeSummaryProvider>
          <ActivityGraphSummaryCard
            label="Home Time"
            value={homeTimeValue}
            unit={homeTimeUnit}
            trend={homeTimeTrend}
            chartData={homeTimeChartData}
            reverseTrend={true}
            color="#FFA500"
          />
        </HomeTimeSummaryProvider>
        <Card additionalClasses={cardStyle}>
          <LocationInsightProvider>
            <LocationStackChart />
          </LocationInsightProvider>
        </Card>
      </div>
    </SectionContainer>
  )
}

export default LocationSection
