import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner.tsx"

import api from "@utilities/api.ts"

import useSurveySummaryStore from "../store/SurveySummaryStore.ts"

const SurveysSummaryProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentSurveyResponse = useSurveySummaryStore((state) => state.setCurrentSurveyResponse)
  const { clientId } = useParams()

  const {
    data: surveyDataResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["surveysSummaryData", clientId],
    retry: false,
    queryFn: async () => {
      const endpoint = `insight/summary/survey/${clientId}`
      const { data, status } = await api.get<SurveySummaryResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching Survey data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && surveyDataResponse) {
      setCurrentSurveyResponse(surveyDataResponse)
    }
  }, [surveyDataResponse, isSuccess, setCurrentSurveyResponse])

  if (isLoading) return <LoadingSpinner size="2x" />
  if (isError)
    return (
      <div className="my-6 w-full text-center text-sm text-neutral-200">Could not load survey data at this time</div>
    )

  return children
}

export default SurveysSummaryProvider
