import { faList, faTable, faTableCellsLarge } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dispatch, SetStateAction } from "react"

import { Tabs, TabsList, TabsTrigger } from "@/shadcn-components/ui/tabs.tsx"

interface Props {
  selected: string
  setSelected: Dispatch<SetStateAction<string>>
  supportedViews: ViewTypes[]
}

const ViewSelector = ({ selected, setSelected, supportedViews }: Props) => {
  const iconMap = {
    list: faList,
    grid: faTableCellsLarge,
    table: faTable,
  }
  return (
    <Tabs onValueChange={setSelected} value={selected}>
      <TabsList>
        {supportedViews.map((view) => (
          <TabsTrigger key={view} value={view}>
            <FontAwesomeIcon icon={iconMap[view]} />
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  )
}

export default ViewSelector
