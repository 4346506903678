import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format, utcToZonedTime } from "date-fns-tz"
import { useState } from "react"

import { nameToColor } from "../../utils/utils.ts"
import useAssignedSurveysStore from "./store/AssignedSurveysStore.ts"

const AssignedSurveysCard: React.FC = () => {
  const currentAssignedSurveys = useAssignedSurveysStore((state) => state.currentAssignedSurveys)
  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 7

  // State for the switches
  const [hideCompletedSurveys, setHideCompletedSurveys] = useState(false)
  const [showOnlyRecurringSurveys, setShowOnlyRecurringSurveys] = useState(false)

  if (!currentAssignedSurveys) return <div>No surveys found</div>

  // Calculate total pages and surveys for the current page
  const totalPages = Math.ceil(currentAssignedSurveys.assigned_surveys.length / itemsPerPage)
  const paginatedSurveys = currentAssignedSurveys.assigned_surveys.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage,
  )

  const goToNextPage = () => setCurrentPage((prev) => (prev + 1 < totalPages ? prev + 1 : prev))
  const goToPreviousPage = () => setCurrentPage((prev) => (prev > 0 ? prev - 1 : 0))
  const arrowStyle = "border border-neutral-100 rounded-full px-4"
  const leftArrowStyle = currentPage <= 0 ? "text-neutral-100" : "text-black"
  const rightArrowStyle = currentPage >= totalPages - 1 ? "text-neutral-100" : "text-black"

  return (
    <div className="flex flex-col items-center w-full space-y-4">
      {/* Pagination Controls */}
      <div className="flex justify-between w-full">
        <button onClick={goToPreviousPage} disabled={currentPage <= 0} className={`${arrowStyle} ${leftArrowStyle}`}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <span>
          Week {currentPage + 1} of {totalPages}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage >= totalPages - 1}
          className={`${arrowStyle} ${rightArrowStyle}`}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

      {/* Filter Switches */}
      <div className="flex space-x-4 mt-2">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={hideCompletedSurveys}
            onChange={() => setHideCompletedSurveys(!hideCompletedSurveys)}
            className="accent-black"
          />
          <span>Hide Completed Surveys</span>
        </label>
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={showOnlyRecurringSurveys}
            onChange={() => setShowOnlyRecurringSurveys(!showOnlyRecurringSurveys)}
            className="accent-black"
          />
          <span>Only Show Recurring Surveys</span>
        </label>
      </div>

      {paginatedSurveys.map((daySurveys, index) => {
        const rawDate = Object.keys(daySurveys)[0]
        const timeZone = "America/New_York"
        const zonedDate = utcToZonedTime(rawDate, timeZone)
        const formattedDate = format(zonedDate, "MMM d, EEEE")
        const surveys = daySurveys[rawDate]

        // Apply filters
        let filteredSurveys = surveys

        if (hideCompletedSurveys) {
          filteredSurveys = filteredSurveys.filter((survey) => !survey.completed_at)
        }

        if (showOnlyRecurringSurveys) {
          filteredSurveys = filteredSurveys.filter((survey) => survey.freq !== null)
        }

        // If no surveys remain after filtering, skip rendering this day
        if (filteredSurveys.length === 0) {
          return null
        }

        return (
          <div key={index} className="w-full hover:bg-neutral-50 border p-3 px-4 rounded-xl">
            <div className="text-lg font-semibold mb-2">{formattedDate}</div>
            <div className="flex flex-col">
              {filteredSurveys.map((survey) => (
                <div key={survey.scheduled_feed_item_id} className="not-last:border-b border-neutral-200">
                  <div className="flex flex-row py-1 mb-0 justify-between">
                    <div className="flex flex-row items-center mr-2">
                      <div
                        className="w-3 h-3 rounded-full mr-1 group relative"
                        style={{ backgroundColor: nameToColor(survey.name) }}
                      ></div>
                      <div className="text-sm mr-2">{survey.name}</div>
                      <div className="text-xs text-neutral-400 rounded-full bg-neutral-100 px-2">{survey.freq}</div>
                    </div>
                    <div className="flex flex-row items-center ml-2">
                      <div className="text-xs text-neutral-400">
                        {survey.completed_at ? <div className="text-xs text-green-600">Completed</div> : "Assigned"}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AssignedSurveysCard
