import Card from "@/components/Cards/Card.tsx"

import SectionContainer from "../atom/SectionContainer"
import DensityGraphAnimated from "../graph/DensityGraphAnimated"

const MoodMapSection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"

  return (
    <>
      <SectionContainer title="Mood Map" description="Mood entries over the last 3 weeks">
        <div className="flex gap-3">
          <Card additionalClasses={cardStyle} padding="p-2">
            <div className="w-full aspect-square">
              <DensityGraphAnimated startFromIndex={0} />
            </div>
          </Card>
          <Card additionalClasses={cardStyle} padding="p-2">
            <div className="w-full aspect-square">
              <DensityGraphAnimated startFromIndex={1} />
            </div>
          </Card>
          <Card additionalClasses={cardStyle} padding="p-2">
            <div className="w-full aspect-square">
              <DensityGraphAnimated startFromIndex={2} />
            </div>
          </Card>
        </div>
      </SectionContainer>
    </>
  )
}

export default MoodMapSection
