import { MoreVertical } from "lucide-react"

import { Button } from "@/shadcn-components/ui/button.tsx"
import { DropdownMenuTrigger } from "@/shadcn-components/ui/dropdown-menu.tsx"

const ActionMenuTrigger = () => {
  return (
    <DropdownMenuTrigger asChild>
      <Button variant="ghost" className="h-8 w-8 p-0">
        <span className="sr-only">Open menu</span>
        <MoreVertical className="h-4 w-4" />
      </Button>
    </DropdownMenuTrigger>
  )
}

export default ActionMenuTrigger
