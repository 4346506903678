import * as d3 from "d3"

class ChoicesHistoryD3 {
  constructor(element, width, height, data) {
    this.element = element
    this.width = width
    this.height = height
    this.data = data
    this.margin = { top: 10, right: 0, bottom: 30, left: 0 }
    this.svg = null

    this.createChart()
  }

  createChart() {
    d3.select(this.element).select("svg").remove()

    if (!this.data || !this.data.responses || this.data.responses.length === 0) {
      return
    }

    // Determine the longest choice and adjust left margin
    const testSvg = d3.select(this.element).append("svg").attr("class", "hidden-svg").style("visibility", "hidden")
    const textElement = testSvg.append("text").attr("class", "hidden-text")

    const maxChoiceLength = Math.max(
      ...this.data.choices.map((choice) => {
        textElement.text(choice)
        return textElement.node().getComputedTextLength()
      }),
    )

    testSvg.remove()

    this.margin.left = Math.min(300, Math.max(20, maxChoiceLength + 10))

    const svg = d3.select(this.element).append("svg").attr("width", this.width).attr("height", this.height)

    const chartGroup = svg.append("g").attr("transform", `translate(${this.margin.left},${this.margin.top})`)

    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom

    // Parse dates and prepare data
    const parseDate = d3.timeParse("%Y-%m-%d")
    const responses = this.data.responses.map((d) => ({
      date: parseDate(d.date),
      values: d.values,
    }))

    // Flatten the responses for multiple values
    const flattenedData = responses.flatMap((response) =>
      response.values.map((value) => ({
        date: response.date,
        choice: value,
      })),
    )

    // Extract unique dates
    const uniqueDates = [...new Set(flattenedData.map((d) => d.date))]

    // Define scales
    const xScale = d3.scaleBand().domain(uniqueDates).range([0, innerWidth]).padding(0.2)

    const yScale = d3.scalePoint().domain(this.data.choices).range([innerHeight, 0]).padding(0.5)

    // Draw horizontal grid lines for y-axis
    chartGroup
      .selectAll(".y-grid-line")
      .data(this.data.choices)
      .enter()
      .append("line")
      .attr("class", "y-grid-line")
      .attr("x1", 0)
      .attr("x2", innerWidth)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "4")

    // Draw vertical grid lines for x-axis
    chartGroup
      .selectAll(".x-grid-line")
      .data(uniqueDates)
      .enter()
      .append("line")
      .attr("class", "x-grid-line")
      .attr("x1", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("x2", (d) => xScale(d) + xScale.bandwidth() / 2)
      .attr("y1", 0)
      .attr("y2", innerHeight)
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "4")

    // Draw axes without lines and ticks
    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat((d) => d3.timeFormat("%m/%d")(new Date(d)))
      .tickSize(0)

    const yAxis = d3.axisLeft(yScale).tickSize(0)

    chartGroup
      .append("g")
      .attr("transform", `translate(0, ${innerHeight})`)
      .call(xAxis)
      .selectAll("path, .tick line")
      .remove()
      .style("font-size", "10px")

    const yAxisGroup = chartGroup.append("g").call(yAxis)
    yAxisGroup.selectAll("path, .tick line").remove()
    yAxisGroup
      .selectAll(".tick text")
      .style("font-size", "10px")
      .attr("text-anchor", "start") // Align text to the left
      .attr("x", -this.margin.left + 10) // Add padding to the left

    // Wrap text for choices
    yAxisGroup.selectAll(".tick text").call(this.wrapText, this.margin.left - 10)

    // Draw dots for responses
    chartGroup
      .selectAll("circle")
      .data(flattenedData)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.date) + xScale.bandwidth() / 2)
      .attr("cy", (d) => yScale(d.choice))
      .attr("r", 6)
      .attr("fill", "#000000")
  }

  // Function to wrap text for y-axis labels
  wrapText(textNodes, maxWidth) {
    textNodes.each(function () {
      const text = d3.select(this)
      const words = text.text().split(/\s+/).reverse()
      let word
      let line = []
      let lineNumber = 0
      const lineHeight = 1.1
      const x = text.attr("x")
      const y = text.attr("y")
      const dy = parseFloat(text.attr("dy") || 0)

      let tspan = text
        .text(null)
        .append("tspan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", `${dy}em`)
        .style("font-size", "12px")

      while ((word = words.pop())) {
        line.push(word)
        tspan.text(line.join(" "))
        if (tspan.node().getComputedTextLength() > maxWidth) {
          line.pop()
          tspan.text(line.join(" "))
          line = [word]
          tspan = text
            .append("tspan")
            .attr("x", x)
            .attr("y", y)
            .attr("dy", `${++lineNumber * lineHeight + dy}em`)
            .text(word)
            .style("font-size", "12px")
        }
      }
    })
  }
}

export default ChoicesHistoryD3
