import { useEffect, useRef } from "react"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import NumericalHistoryD3 from "../../graph/D3Files/NumericalHistoryD3.js"
import QuestionHeader from "./QuestionHeader.tsx"

interface NumericalHistoryProps {
  indexString?: string
  graphData?: SurveyInsightQuestionHistory
}

const NumericalHistory = ({ graphData, indexString = "Q" }: NumericalHistoryProps) => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const currentGraphData = graphData
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0

  useEffect(() => {
    if (isValidSize) {
      new NumericalHistoryD3(chartArea.current, containerSize.width, containerSize.height, currentGraphData)
    }
  }, [containerSize, graphData, currentGraphData, isValidSize])

  return (
    <div className="w-full rounded-xl flex flex-col mb-6">
      <QuestionHeader
        indexString={indexString}
        questionText={graphData?.question_text}
        questionType={graphData?.question_type}
      />
      <div className="w-full h-auto overflow-hidden" ref={containerRef}>
        <div className={`chart-area flex-1 w-full overflow-hidden h-60`} ref={chartArea}></div>
      </div>
    </div>
  )
}

export default NumericalHistory
