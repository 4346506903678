import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query"
import { ColumnDef } from "@tanstack/react-table"
import { Link } from "react-router-dom"

import { DataTable, DataTableColumnHeader } from "@components/Tables/DataTable.tsx"

import AddGroup from "@features/Admin/Groups/AddGroup/AddGroup.tsx"
import ActionButtons from "@features/Admin/Groups/ManageGroupsTable/ActionButtons.tsx"
import useManageGroupsStore from "@features/Admin/Groups/stores/ManageGroupsStore.ts"

interface ManageGroupsTableProps {
  refetch: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<Group[], Error>>
}

const ManageGroupsTableColumns: ColumnDef<Group & GroupListActions>[] = [
  {
    id: "Group Name",
    accessorKey: "name",
    header: ({ column }) => <DataTableColumnHeader column={column} title={"Name"} />,
    cell: ({ row }) => <Link to={`${row.original.group_id}/overview`}>{row.original.name}</Link>,
  },
  {
    id: "Description",
    accessorKey: "description",
    header: "Description",
  },
  {
    id: "Kind",
    accessorKey: "kind",
    header: ({ column }) => <DataTableColumnHeader column={column} title={"Type"} />,
    cell: ({ row }) => {
      const group = row.original
      return (
        <span className="capitalize text-xs bg-gray-200 text-gray-600 rounded-full px-2 py-1 mr-1">{group.kind}</span>
      )
    },
  },
  {
    id: "member_count",
    accessorKey: "member_count",
    header: ({ column }) => <DataTableColumnHeader column={column} title={"Member Count"} />,
    cell: ({ row }) => <span>{row.original.member_count} users</span>,
  },
  {
    id: "Actions",
    cell: ({ row }) => <ActionButtons row={row} />,
  },
]

const ManageGroupsTable = ({ refetch }: ManageGroupsTableProps) => {
  const groups = useManageGroupsStore((state) => state.groups)
  // todo: create a modal with a form for creating a group
  const ActionButtons = [<AddGroup onReload={() => void refetch()} />]

  const enrichedGroups = groups.map((group) => ({ ...group, refetch }))

  return (
    <DataTable
      title={"Groups"}
      ActionButtonsSection={ActionButtons}
      columns={ManageGroupsTableColumns}
      data={enrichedGroups}
    />
  )
}

export default ManageGroupsTable
