import { useQuery } from "@tanstack/react-query"
import { ReactElement, cloneElement, useEffect } from "react"

import useManageGroupsStore from "@features/Admin/Groups/stores/ManageGroupsStore.ts"

import api from "@utilities/api.ts"

interface ManageGroupsProviderProps {
  children: ReactElement
}

const ManageGroupsProvider = ({ children }: ManageGroupsProviderProps) => {
  const setGroups = useManageGroupsStore((state) => state.setGroups)
  const { data, refetch, isPending, isError, error } = useQuery<Group[]>({
    queryKey: ["groups"],
    queryFn: async () => {
      const response = await api.get<Group[]>("/groups")
      return response.data
    },
  })

  useEffect(() => {
    if (data) {
      setGroups(data)
    }
  }, [setGroups, data])
  if (isPending) return <div>Loading...</div>
  if (isError) return <div>Error: {error.message}</div>
  return children ? cloneElement(children, { refetch }) : null
}

export default ManageGroupsProvider
