import { faSortUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface ScoreBarProps {
  score: number
  min_score: number
  max_score: number
  medium: number
  high: number
}

const ScoreBar: React.FC<ScoreBarProps> = ({ score, min_score, max_score, medium, high }) => {
  const getSectionWidth = (end: number, start: number) => {
    return `${((end - start) / (max_score - min_score)) * 100}%`
  }
  const bgColorMap = {
    low: "bg-green-400",
    medium: "bg-yellow-400",
    high: "bg-red-400",
    default: "bg-neutral-100",
  }

  const getTrianglePosition = () => {
    const scorePosition = ((score - min_score) / (max_score - min_score)) * 100
    return `${scorePosition}%`
  }

  const getTriangleColor = () => {
    if (score <= medium) return "text-green-400"
    if (score <= high) return "text-yellow-400"
    return "text-red-400"
  }

  const triangleColor = getTriangleColor()

  return (
    <div className="relative flex items-center gap-1 max-w-sm min-w-[200px]">
      {/* Min label */}
      <span className="text-xs text-neutral-300">{min_score}</span>

      {/* Score bar sections with labels */}
      <div className="relative flex-grow flex items-center">
        <div className="flex w-full h-[6px] gap-1">
          <div className={`rounded-lg ${bgColorMap.low}`} style={{ width: getSectionWidth(medium, min_score) }} />
          <div className={`rounded-lg ${bgColorMap.medium}`} style={{ width: getSectionWidth(high, medium) }} />
          <div className={`rounded-lg ${bgColorMap.high}`} style={{ width: getSectionWidth(max_score, high) }} />
        </div>

        {/* Triangle marker positioned above the bar */}
        <FontAwesomeIcon
          icon={faSortUp}
          className={`absolute ${triangleColor}`}
          style={{
            left: `calc(${getTrianglePosition()} - 6px)`,
            top: "-18px",
            transform: "rotate(180deg)",
          }}
        />

        {/* Medium and High labels */}
        <span
          className="absolute text-xs text-neutral-300"
          style={{
            left: `${((medium - min_score) / (max_score - min_score)) * 100}%`,
            transform: "translateX(-50%)",
            top: "8px",
          }}
        >
          {medium}
        </span>
        <span
          className="absolute text-xs text-neutral-300"
          style={{
            left: `${((high - min_score) / (max_score - min_score)) * 100}%`,
            transform: "translateX(-50%)",
            top: "8px",
          }}
        >
          {high}
        </span>
      </div>

      {/* Max label */}
      <span className="text-xs text-neutral-300">{max_score}</span>
    </div>
  )
}

export default ScoreBar
