import { useOrganization } from "@clerk/clerk-react"
import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import LoadingSpinner from "@components/Loading/LoadingSpinner.tsx"

import useSurveyStore from "@features/Surveys/stores/surveyStore.ts"

import api from "@utilities/api.ts"

const SurveyProvider = ({ children }: { children: ReactNode }) => {
  const [surveySetIsPending, setSurveySetIsPending] = useState(true)
  const setSurveys = useSurveyStore((state) => state.setSurveys)
  const setSurveySets = useSurveyStore((state) => state.setSurveySets)
  const { organization } = useOrganization()
  const location = useLocation()
  const state = location.state as { clientId: string }
  const clientId = state?.clientId

  const { data: surveys, isPending: surveyIsPending } = useQuery({
    queryKey: ["Surveys"],
    queryFn: async () => {
      const response = await api.get<Survey[]>("/surveys")
      if (response.status == 200) return response.data
      else throw new Error(response.statusText)
    },
  })

  useEffect(() => {
    const fetchTemplateFeedItems = async () => {
      const response = await api.get<TemplateFeedItem[]>(
        `/template_feed_items/${organization?.slug || "personily"}?target_user_id=${clientId}`,
      )
      if (response.status == 200) return response.data
      else throw new Error(response.statusText)
    }

    const fetchData = async () => {
      const data = await fetchTemplateFeedItems()
      if (data) {
        setSurveySets(data)
        setSurveySetIsPending(false)
      }
    }

    void fetchData()
  }, [clientId, organization?.slug, setSurveySets])

  useEffect(() => {
    if (surveys) {
      setSurveys(surveys)
    }
  }, [setSurveys, surveys])

  if (surveyIsPending || surveySetIsPending) return <LoadingSpinner />

  return children
}
export default SurveyProvider
