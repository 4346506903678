import { TableCell, TableRow } from "@/shadcn-components/ui/table.tsx"

interface Props {
  name: string
  dates_with_responses: { value: string }[]
  offsetBegin: number
  offsetEnd: number
}

const AttendanceClientRow = ({ name, dates_with_responses, offsetBegin, offsetEnd }: Props) => {
  const valueMap = (value: string) => {
    if (value === "Yes") return { content: "✅", backgroundColor: "bg-green-100" }
    if (value === "No") return { content: "❌", backgroundColor: "bg-red-100" }
    if (value === "No Session") return { content: "--", backgroundColor: "bg-white" }
    if (value === "N/A") return { content: "?", backgroundColor: "bg-gray-100" }
    return { content: "", backgroundColor: "" }
  }
  return (
    <TableRow>
      <TableCell className={"w-[200px]"}>{name}</TableCell>
      {dates_with_responses.slice(offsetBegin, offsetEnd).map(({ value }) => (
        <TableCell className={`${valueMap(value).backgroundColor} text-center`}>{valueMap(value).content}</TableCell>
      ))}
    </TableRow>
  )
}

export default AttendanceClientRow
