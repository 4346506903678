import { Card } from "@/shadcn-components/ui/card.tsx"

import useGroupStore from "@features/Admin/Groups/stores/GroupStore.ts"
import AttendanceChart from "@features/Attendance/AttendanceChart.tsx"

const GroupOverview = () => {
  const { group_id, description } = useGroupStore((state) => state.group)
  if (group_id)
    return (
      <div className={"flex flex-1 flex-col w-full gap-4"}>
        <Card className={"w-full p-4"}>
          <h4>Description</h4>
          {description}
        </Card>
        <AttendanceChart groupId={group_id} />
      </div>
    )
  return <div>Loading...</div>
}

export default GroupOverview
