import Card from "@/components/Cards/Card.tsx"

import ChoicesHistory from "./question/ChoicesHistory.tsx"
import NumericalHistory from "./question/NumericalHistory.tsx"
import QuestionHistory from "./question/QuestionHistory.tsx"

interface SurveyDetailsProps {
  selectedSurveyData: SurveyInsightQuestionHistory[] | null
  cardStyle: string
}

const renderQuestionComponent = (question: SurveyInsightQuestionHistory, index: number) => {
  switch (question.question_type) {
    case "textbox":
      return <QuestionHistory questionData={question} indexString={index.toString()} />
    case "datepicker":
      return <QuestionHistory questionData={question} indexString={index.toString()} />
    case "choices_single":
      return <ChoicesHistory graphData={question} indexString={index.toString()} />
    case "choices_multiple":
      return <ChoicesHistory graphData={question} indexString={index.toString()} />
    case "dropdown_single":
      return <QuestionHistory questionData={question} indexString={index.toString()} />
    case "dropdown_multiple":
      return <QuestionHistory questionData={question} indexString={index.toString()} />
    case "numerical":
      return <NumericalHistory graphData={question} indexString={index.toString()} />
    default:
      return <QuestionHistory questionData={question} indexString={index.toString()} />
  }
}
const SurveyDetails: React.FC<SurveyDetailsProps> = ({ selectedSurveyData, cardStyle }) => {
  return (
    <div className="flex gap-3 w-full">
      {selectedSurveyData ? (
        <Card additionalClasses={cardStyle}>
          <div className="w-full">
            {selectedSurveyData.map((question, i) => (
              <div key={question.question_text}>{renderQuestionComponent(question, i + 1)}</div>
            ))}
          </div>
        </Card>
      ) : (
        <Card additionalClasses={cardStyle}>Please select a survey to view details</Card>
      )}
    </div>
  )
}

export default SurveyDetails
