import QuestionHeader from "./QuestionHeader"

interface QuestionHistoryProps {
  indexString?: string
  questionData?: SurveyInsightQuestionHistory
}

const QuestionHistory = ({ questionData, indexString = "Q" }: QuestionHistoryProps) => {
  return (
    <div className="w-full rounded-xl flex flex-col mb-6">
      <QuestionHeader
        indexString={indexString}
        questionText={questionData?.question_text}
        questionType={questionData?.question_type}
      />

      <div className="w-full">
        {questionData?.responses?.length ? (
          <div className="ml-2 my-2">
            {questionData.responses.map((response, idx) => (
              <div key={idx} className="flex flex-row not-last:border-b items-center my-3 pb-2">
                <div className="text-xs text-neutral-300 w-12 text mr-3">
                  {response.date.slice(-5).replace("-", "/")}
                </div>
                <div className="text-gray-700 text-left w-full">{response.values.join(", ")}</div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500">No responses available</p>
        )}
      </div>
    </div>
  )
}

export default QuestionHistory
