import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"

export function getCurrentTimeUTC(): string {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const date = new Date()
  const utcDate = zonedTimeToUtc(date, timeZone)
  return utcDate.toISOString()
}

export function getTimestamp() {
  const date = new Date()
  return date.getTime()
}

// Function to get local time from a given date in a specific or default time zone and return as a string
export function getLocalTimeStringFromDate(date: string | Date, timeZone?: string): string {
  if (!timeZone) {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  }
  const localDate = utcToZonedTime(date, timeZone)
  return format(localDate, "yyyy-MM-dd HH:mm:ssXXX", { timeZone })
}

// Function to get the current local time string
export function getCurrentLocalTimeString(): string {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const date = new Date()
  const localDate = utcToZonedTime(date, timeZone)
  return format(localDate, "yyyy-MM-dd HH:mm:ssXXX", { timeZone })
}

// Function to get the current local date string
export function getCurrentLocalDate(): string {
  // get the current datetime for the local browser (yyyy-MM-ddTHH:mm:ss)
  const date = new Date()
  return date.toISOString().split("Z")[0]
}
