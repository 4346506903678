import { create } from "zustand"

interface LocationInsightStore {
  currentLocationResponse: LocationInsightResponse | null
  setCurrentLocationResponse: (response: LocationInsightResponse) => void
}

const useLocationInsightStore = create<LocationInsightStore>((set) => ({
  currentLocationResponse: null,
  setCurrentLocationResponse: (currentLocationResponse) => set({ currentLocationResponse }),
}))

export default useLocationInsightStore
