import { useState } from "react"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"
import { success } from "@utilities/logger.ts"

interface TestResponse {
  endpoint: string
  data?: ApiResponseData
  status?: number
  error?: ErrorMessageTypes
  roundTripTime?: number // Add RTT property
}

type ApiResponseData = APIInsightsGraphResponse

const TestGetApis: React.FC = () => {
  const endpoints = ["/insight/activity/e410f2fa-5905-4abe-b7af-b43210110c64"]

  const [responses, setResponses] = useState<TestResponse[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchApiData = async (endpoint: string): Promise<TestResponse> => {
    const startTime = Date.now()
    try {
      const response = await api.get<ApiResponseData>(endpoint)
      const roundTripTime = Date.now() - startTime
      return {
        endpoint,
        data: response.data,
        status: response.status,
        roundTripTime,
      }
    } catch (error) {
      const err = error as ErrorMessageTypes
      return {
        endpoint,
        error: err || "An error occurred",
        roundTripTime: Date.now() - startTime,
      }
    }
  }

  const handleTestApis = async () => {
    setIsLoading(true)
    setResponses([])

    const newResponses: TestResponse[] = []

    for (const endpoint of endpoints) {
      const response = await fetchApiData(endpoint)
      newResponses.push(response)
      setResponses([...newResponses])
    }

    setIsLoading(false)
  }

  const copyToClipboard = async (data: string) => {
    const jsonData = JSON.stringify(JSON.parse(data), null, 2)
    await navigator.clipboard.writeText(jsonData)
    success("Copied to clipboard")
  }

  const isStatusSuccess = (status: number | undefined) => (status ? status >= 200 && status < 300 : false)

  return (
    <div className="w-full flex flex-col items-center">
      <button
        className="w-48 mt-4 mb-4 p-2 bg-black text-white rounded-full hover:bg-neutral-700"
        onClick={() => {
          void handleTestApis()
        }}
        disabled={isLoading}
      >
        Test All GET APIs
      </button>

      {isLoading && (
        <div className="h-12 w-12">
          <LoadingSpinner />
        </div>
      )}
      <div className="w-full flex flex-col items-center">
        {responses.map((response, index) => (
          <div key={index} className="mb-4 p-2 bg-gray-100 rounded shadow w-2/3">
            <h4 className={isStatusSuccess(response.status) ? "text-green-700" : "text-red-700"}>
              {response.endpoint}
            </h4>
            <div className="text-xs">
              <div>Status: {response.status}</div>
              <div>Round Trip Time: {response.roundTripTime} ms</div>
              <div>Data:</div>
            </div>
            <div className="overflow-auto max-h-40 border border-neutral-500 rounded p-2">
              {response.data && (
                <pre className="text-xs whitespace-pre-wrap">{JSON.stringify(response.data, null, 2)}</pre>
              )}
            </div>
            {response.data && (
              <button
                className="mt-2 p-1 text-sm bg-black px-4 text-white rounded-full hover:bg-neutral-700"
                onClick={() => {
                  void copyToClipboard(JSON.stringify(response.data, null, 2).toString())
                }}
              >
                Copy
              </button>
            )}
            {response.error && <p className="text-red-700">{response.error.toString()}</p>}
          </div>
        ))}
      </div>
    </div>
  )
}

export default TestGetApis
