import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import api from "@utilities/api.ts"

import ActivityGraphCardLoading from "../ActivityGraphCardLoading.tsx"
import useSleepSummaryStore from "../store/SleepSummaryStore.ts"

const SleepSummaryProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentSleepData = useSleepSummaryStore((state) => state.setCurrentSleepResponse)
  const { clientId } = useParams()

  const {
    data: sleepDataResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["sleepSummaryData", clientId],
    retry: false,
    queryFn: async () => {
      const endpoint = `insight/summary/sleep/${clientId}`
      const { data, status } = await api.get<SleepSummaryResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching Sleep data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && sleepDataResponse) {
      setCurrentSleepData(sleepDataResponse)
    }
  }, [sleepDataResponse, isSuccess, setCurrentSleepData])

  if (isLoading) return <ActivityGraphCardLoading label="Daily Sleep" />
  if (isError) return <ActivityGraphCardLoading label="Daily Sleep" isError={true} />

  return children
}

export default SleepSummaryProvider
