import { useEffect, useRef, useState } from "react"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import MoodStackGraphD3 from "../graph/D3Files/MoodStackGraphD3"
import MoodInsightAnswerList from "./MoodInsightAnswerList"
import useMoodInsightStore from "./store/InsightReportMoodStore"

const MoodInsightChart: React.FC = () => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0

  const currentGraphData = useMoodInsightStore((state) => state.currentMoodResponse)

  const [currentPage, setCurrentPage] = useState(2)
  const daysPerPage = 7

  const getPageName = (page: number) => {
    switch (page) {
      case 0:
        return "Last 15 - 21 days"
      case 1:
        return "Last 8 - 14 days"
      case 2:
        return "Last 7 days"
      default:
        return "Unknown"
    }
  }

  const sortedDates = Object.keys(currentGraphData || {}).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
  const paginatedData = sortedDates.slice(currentPage * daysPerPage, currentPage * daysPerPage + daysPerPage)

  const displayedData = paginatedData.reduce(
    (acc, date) => {
      if (currentGraphData && acc && currentGraphData[date]) {
        acc[date] = currentGraphData[date]
      }
      return acc
    },
    {} as typeof currentGraphData,
  )

  const totalPages = Math.ceil(sortedDates.length / daysPerPage)

  useEffect(() => {
    if (isValidSize && chartArea.current) {
      new MoodStackGraphD3(chartArea.current, containerSize.width, containerSize.height, displayedData)
    }
  }, [containerSize, displayedData, isValidSize])

  return (
    <div className="w-full h-auto">
      {/* Pagination Controls */}
      <div className="flex flex-col items-center mt-4 w-full">
        <div className="flex justify-between w-full">
          <button
            className="px-4 py-2 border border-neutral-100 rounded-full hover:bg-gray-300 disabled:text-neutral-100"
            disabled={currentPage === 0}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
          >
            Previous
          </button>
          <div>
            <div className="px-4 py-2 border border-neutral-100 rounded-full">{getPageName(currentPage)}</div>
          </div>

          <button
            className="px-4 py-2 border border-neutral-100 rounded-full hover:bg-gray-300 disabled:text-neutral-100"
            disabled={currentPage === totalPages - 1}
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))}
          >
            Next
          </button>
        </div>
      </div>

      <div className="w-full h-60 overflow-hidden" ref={containerRef}>
        <div className="chart-area flex-1 w-full h-full overflow-hidden" ref={chartArea}></div>
      </div>

      <MoodInsightAnswerList data={displayedData} />
    </div>
  )
}

export default MoodInsightChart
