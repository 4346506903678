import SectionContainer from "../atom/SectionContainer"
import SurveysInsightView from "./SurveysInsightView"
import SurveysInsightProvider from "./provider/SurveysInsightProvider"

const SurveySection: React.FC = () => {
  return (
    <SectionContainer title="Surveys" description="Survey responses from the past 30 days">
      <SurveysInsightProvider>
        <SurveysInsightView />
      </SurveysInsightProvider>
    </SectionContainer>
  )
}

export default SurveySection
