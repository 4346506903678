const SurveyItemRowHeader: React.FC = () => {
  return (
    <div className="flex flex-row justify-between items-center w-full">
      <div className="min-w-[150px] text-xs text-neutral-300">Survey</div>
      <div className="min-w-[200px] text-xs text-neutral-300">Severity Scale</div>
      <div className="min-w-[50px] text-xs text-neutral-300">Score</div>
      <div className="min-w-[50px] text-xs text-neutral-300">Trend</div>
      <div className="min-w-[50px] text-xs text-neutral-300">Week</div>
    </div>
  )
}

export default SurveyItemRowHeader
