import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import CalendarSlider from "@features/Calendar/CalendarSlider"
import HeaderCard from "@features/Calendar/HeaderCard"
import CalendarProvider from "@features/Calendar/provider/CalendarProvider"
import FeedRenderer from "@features/Feed/FeedRenderer"
import FeedProvider from "@features/Feed/provider/FeedProvider"
import InsightsGraphContainer from "@features/Graphs/InsightsGraphsContainer.tsx"
import InsightsGraphProvider from "@features/Graphs/provider/InsightsGraphProvider.tsx"

const ClientHome = () => {
  const { date } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (!date) {
      navigate(`/now`, { replace: true })
    }
  }, [date, navigate])

  return (
    <div className="flex flex-1 flex-col w-full items-center justify-center">
      <div className="flex-1 w-full">
        <HeaderCard />
        <CalendarProvider>
          <CalendarSlider />
        </CalendarProvider>

        <div className="mb-3">
          <FeedProvider>
            <FeedRenderer feedType="feed" />
            <FeedRenderer feedType="dig_deeper" />
          </FeedProvider>
        </div>

        <div className="mb-3 mt-8">
          <InsightsGraphProvider>
            <InsightsGraphContainer />
          </InsightsGraphProvider>
        </div>
      </div>
    </div>
  )
}

export default ClientHome
