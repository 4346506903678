import { Outlet } from "react-router-dom"

import GroupHeader from "@features/Admin/Groups/Group/GroupHeader.tsx"
import GroupProvider from "@features/Admin/Groups/providers/GroupProvider.tsx"

const Group = () => {
  return (
    <GroupProvider>
      <div className={"flex flex-col flex-1 items-center gap-4"}>
        <GroupHeader />
        <Outlet />
      </div>
    </GroupProvider>
  )
}

export default Group
