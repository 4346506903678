import SectionContainer from "../atom/SectionContainer"
import SurveysInsightProvider from "../survey/provider/SurveysInsightProvider"
import HabitsInsightView from "./HabitsInsightView"

const SleepSection: React.FC = () => {
  return (
    <SectionContainer title="Daily Habits" description="Habit survey responses from the past 30 days">
      <SurveysInsightProvider>
        <HabitsInsightView />
      </SurveysInsightProvider>
    </SectionContainer>
  )
}

export default SleepSection
