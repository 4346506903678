import { useEffect, useRef } from "react"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import LocationStackGraphD3 from "../graph/D3Files/LocationStackGraphD3.js"
import useLocationInsightStore from "./store/LocationInsightStore.ts"

const LocationStackChart: React.FC = () => {
  const graphData = useLocationInsightStore((state) => state.currentLocationResponse)?.locations
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0

  useEffect(() => {
    const hasValidData = graphData && Object.keys(graphData).length > 0

    if (isValidSize && hasValidData && chartArea.current && containerSize.width && containerSize.height) {
      new LocationStackGraphD3(chartArea.current, containerSize.width, containerSize.height, graphData)
    }
  }, [containerSize, graphData, isValidSize])

  return (
    <div className="w-full h-auto overflow-hidden" ref={containerRef}>
      <div className="chart-area flex-1 w-full h-128 overflow-hidden" ref={chartArea}></div>
    </div>
  )
}

export default LocationStackChart
