import { useQuery } from "@tanstack/react-query"

import api from "@utilities/api.ts"

interface Props {
  groupId: string
}

const useAttendanceChart = ({ groupId }: Props) => {
  const { data, isLoading } = useQuery({
    queryKey: ["attendance", groupId],
    queryFn: async () => {
      const { data, status } = await api.get<AttendanceReport>(`/attendance/group/${groupId}`)
      if (status === 200) return data
      return { checkins: [], dates: [] }
    },
  })

  return {
    data,
    isLoading,
  }
}
export default useAttendanceChart
