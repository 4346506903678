import { faHospital } from "@fortawesome/free-solid-svg-icons"

import useOrgSelectionStore from "@/core/Authentication/stores/OrgSelectionStore.ts"

import CardButton from "@components/Buttons/CardButton.tsx"

interface OrgListProps {
  selectedOrganization: string
  setSelectedOrganization: (orgKey: string) => void
}

const OrgList = ({ selectedOrganization, setSelectedOrganization }: OrgListProps) => {
  const orgs = useOrgSelectionStore((state) => state.orgs)

  return (
    <div className={"flex flex-col gap-2"}>
      {orgs.map((organization) => (
        <CardButton
          title={organization.name}
          description={organization.location}
          bannerImage={organization.image_urls.light}
          onClick={() => setSelectedOrganization(organization.org_key)}
          imgClassName={"ml-3"}
          selected={selectedOrganization === organization.org_key}
        />
      ))}
      <CardButton
        title={"Other"}
        description={"I'm signing up for a provider not listed here"}
        icon={faHospital}
        onClick={() => setSelectedOrganization("other")}
        selected={selectedOrganization === "other"}
      />
    </div>
  )
}
export default OrgList
