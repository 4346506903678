import { surveyKeyToUpperCaseTitle } from "../../utils/utils.ts"
import SurveyTitleButton from "./SurveyTitleButton"

interface SurveyButtonGroupProps {
  surveyKeys: string[]
  selectedSurveyKey: string
  onSurveyClick: (surveyKey: string) => void
}

const SurveyButtonGroup: React.FC<SurveyButtonGroupProps> = ({ surveyKeys, selectedSurveyKey, onSurveyClick }) => {
  return (
    <div className="gap-2">
      {surveyKeys.map((surveyKey) => (
        <SurveyTitleButton
          key={surveyKey}
          isSelected={surveyKey == selectedSurveyKey}
          onClick={() => onSurveyClick(surveyKey)}
        >
          {surveyKeyToUpperCaseTitle(surveyKey)}
        </SurveyTitleButton>
      ))}
    </div>
  )
}

export default SurveyButtonGroup
