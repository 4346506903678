import MoodChartSection from "./MoodChartSection"
import MoodMapSection from "./MoodMapSection"
import InsightReportMoodProvider from "./provider/InsightReportMoodProvider"

const MoodPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-full">
        <h3>Mood</h3>
      </div>
      <InsightReportMoodProvider>
        <MoodMapSection />
        <MoodChartSection />
      </InsightReportMoodProvider>
    </div>
  )
}

export default MoodPage
