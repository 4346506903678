import { useQuery } from "@tanstack/react-query"
import { format } from "date-fns"
import { ReactNode, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import Card from "@components/Cards/Card.tsx"

import api from "@utilities/api.ts"

import useFeedStore from "../stores/FeedStore"

const FeedProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentFeedResponse = useFeedStore((state) => state.setCurrentFeedResponse)

  const { date } = useParams()

  const currentDateTime = useMemo(() => {
    if (date === "now") {
      const now = new Date()
      return format(now, "yyyy-MM-dd'T'HH:mm:ss")
    }
    return date
  }, [date])

  const {
    data: FeedAPIResponse,
    isSuccess: isSuccessFeed,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["feedData", currentDateTime],
    retry: false,
    queryFn: async () => {
      const endpoint = "/feed/self/" + currentDateTime + "?debug=True"
      const response = await api.get<FeedAPIResponse>(endpoint)
      if (response.status === 200) {
        return response.data
      } else throw new Error("Fetching Feed data failed")
    },
  })

  useEffect(() => {
    if (isSuccessFeed) {
      // TODO(05/14/24): This is a temporary fix till the API is updated
      setCurrentFeedResponse(FeedAPIResponse.data)
    }
  }, [FeedAPIResponse, isSuccessFeed, setCurrentFeedResponse, currentDateTime])

  if (isLoading)
    return (
      <div className="mt-6">
        <Card backgroundColor="neutral-50" additionalClasses="shadow-none">
          <LoadingSpinner size="2x" />
        </Card>
      </div>
    )
  if (isError)
    return (
      <div className="mt-6">
        <Card backgroundColor="neutral-50" additionalClasses="shadow-none">
          Failed to load feed
        </Card>
      </div>
    )
  return children
}

export default FeedProvider
