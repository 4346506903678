import { format, utcToZonedTime } from "date-fns-tz"

export function brightenColor(hexColor, percent) {
  hexColor = hexColor.replace("#", "")

  let r = parseInt(hexColor.substring(0, 2), 16)
  let g = parseInt(hexColor.substring(2, 4), 16)
  let b = parseInt(hexColor.substring(4, 6), 16)

  r = Math.min(255, Math.floor(r + (255 - r) * percent))
  g = Math.min(255, Math.floor(g + (255 - g) * percent))
  b = Math.min(255, Math.floor(b + (255 - b) * percent))

  const newHex = `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`
  return newHex
}

export function getLocalTime(isoDate, timeZone) {
  const zonedTime = utcToZonedTime(isoDate, timeZone)
  return format(zonedTime, "HH:mm", { timeZone })
}

export function formatDate(date) {
  return date.toLocaleDateString(undefined, {
    month: "short",
    day: "numeric",
  })
}
