import { useQuery } from "@tanstack/react-query"
import { ReactNode, useEffect } from "react"
import { useParams } from "react-router-dom"

import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import api from "@utilities/api.ts"

import useSurveysInsightStore from "../store/SurveysInsightStore.ts"

const SurveysInsightProvider = ({ children }: { children: ReactNode }) => {
  const setCurrentSurveysInsight = useSurveysInsightStore((state) => state.setCurrentSurveysInsight)
  const { clientId } = useParams()

  const {
    data: surveysInsightDataResponse,
    isSuccess,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["surveysInsightData", clientId],
    retry: false,
    queryFn: async () => {
      const endpoint = `insight/detail/survey/${clientId}`
      const { data, status } = await api.get<SurveysInsightResponse>(endpoint)
      if (status === 200) return data
      throw new Error("Fetching surveys insight data failed")
    },
  })

  useEffect(() => {
    if (isSuccess && surveysInsightDataResponse) {
      setCurrentSurveysInsight(surveysInsightDataResponse)
    }
  }, [surveysInsightDataResponse, isSuccess, setCurrentSurveysInsight])

  if (isLoading)
    return (
      <div className="my-6">
        <LoadingSpinner size="2x" />
      </div>
    )

  if (isError) return <div className="my-6">Could not load surveys insights at this time</div>

  return children
}

export default SurveysInsightProvider
