import * as d3 from "d3"
import { round } from "lodash"

import { formatDate, getLocalTime } from "./utils.js"

class MoodStackGraphD3 {
  constructor(element, width, height, data) {
    this.element = element
    this.width = width
    this.height = height
    this.data = data
    this.margin = { top: 20, right: 10, bottom: 30, left: 10 }
    this.svg = null

    this.createChart()
  }

  createChart() {
    d3.select(this.element).select("svg").remove()

    if (!this.data || Object.keys(this.data).length === 0) {
      console.warn("Empty data, skipping chart creation.")
      return
    }

    const svg = d3.select(this.element).append("svg").attr("width", this.width).attr("height", this.height)

    const chartGroup = svg.append("g").attr("transform", `translate(${this.margin.left},${this.margin.top})`)

    this.svg = chartGroup

    const innerWidth = this.width - this.margin.left - this.margin.right
    const innerHeight = this.height - this.margin.top - this.margin.bottom

    const parseDate = d3.timeParse("%Y-%m-%d")

    // Prepare data
    const dates = Object.keys(this.data).map((d) => ({
      date: parseDate(d),
      moods: this.data[d],
    }))

    // Define scales
    const xScale = d3
      .scaleBand()
      .domain(dates.map((d) => d.date))
      .range([0, innerWidth])
      .padding(0.1)

    const rectHeight = 20

    // Prepare mood data for binding
    const moodData = dates.flatMap((d) => {
      const sortedMoods = d.moods.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at))

      const totalMoods = sortedMoods.length
      const totalHeight = rectHeight * totalMoods
      const yStart = (innerHeight - totalHeight) / 2

      return sortedMoods.map((mood, index) => {
        return {
          date: d.date,
          mood: mood,
          yPosition: yStart + index * rectHeight,
        }
      })
    })

    // Draw mood rectangles
    chartGroup
      .selectAll("rect")
      .data(moodData)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(d.date))
      .attr("y", (d) => d.yPosition)
      .attr("rx", rectHeight / 2)
      .attr("ry", rectHeight / 2)
      .attr("width", xScale.bandwidth())
      .attr("height", rectHeight)
      .attr("opacity", (d) => d.mood.intensity)
      .attr("fill", (d) => d.mood.color)

    // Add mood name on the left of the rectangle
    chartGroup
      .selectAll(".mood-name-text")
      .data(moodData)
      .enter()
      .append("text")
      .attr("class", "mood-name-text")
      .attr("x", (d) => xScale(d.date) + 5)
      .attr("y", (d) => d.yPosition + rectHeight / 2)
      .attr("text-anchor", "start")
      .attr("alignment-baseline", "central")
      .style("fill", "#000")
      .style("font-size", "12px")
      .text((d) => d.mood.mood_name)
      .attr("pointer-events", "none")

    // Add intensity on the right of the rectangle
    chartGroup
      .selectAll(".intensity-text")
      .data(moodData)
      .enter()
      .append("text")
      .attr("class", "intensity-text")
      .attr("x", (d) => xScale(d.date) + xScale.bandwidth() - 5)
      .attr("y", (d) => d.yPosition + rectHeight / 2)
      .attr("text-anchor", "end")
      .attr("alignment-baseline", "central")
      .style("fill", "#000")
      .style("font-size", "12px")
      .text((d) => Math.floor(d.mood.intensity * 10))
      .attr("pointer-events", "none")

    // Create x-axis
    const dayAbbreviations = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
    const xAxis = d3.axisBottom(xScale).tickFormat((d) => dayAbbreviations[d.getDay()])

    const xAxisG = chartGroup.append("g").attr("transform", `translate(0, ${innerHeight})`).call(xAxis)

    xAxisG.selectAll("path, line").style("stroke", "#888888")

    xAxisG.selectAll("text").attr("dy", "1em").style("color", "#888888").style("text-anchor", "middle")

    // Add black rounded rectangle beneath weekend labels and date above
    xAxisG.selectAll(".tick").each(function (d) {
      const day = d.getDay()
      const tick = d3.select(this)
      const rectHeight = 16
      const rectWidth = 18
      const roundRadius = 6

      const dateFormatter = d3.timeFormat("%b %d")
      const formattedDate = dateFormatter(d)

      // Insert a rounded rectangle behind the text for weekends
      if (day === 0 || day === 6) {
        tick
          .insert("rect", "text")
          .attr("x", -rectWidth / 2)
          .attr("y", rectHeight / 2)
          .attr("width", rectWidth)
          .attr("height", rectHeight)
          .attr("rx", roundRadius)
          .attr("ry", roundRadius)
          .style("fill", "black")

        // Change text color to white for weekend labels
        tick.select("text").style("fill", "white")
      }

      // Add date above the tick
      tick
        .append("text")
        .attr("x", 0)
        .attr("y", -rectHeight + 8)
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "central")
        .style("fill", "#888888")
        .style("font-size", "11px")
        .text(formattedDate)
    })

    this.addTooltip(chartGroup)
  }

  addTooltip(svg) {
    // Select or create the tooltip
    let tooltip = d3.select(this.element).select(".thumbnail-tooltip")
    if (tooltip.empty()) {
      tooltip = d3
        .select(this.element)
        .append("div")
        .attr("class", "thumbnail-tooltip")
        .style("position", "absolute")
        .style("padding", "4px 12px")
        .style("background", "#000")
        .style("border-radius", "12px")
        .style("color", "#fff")
        .style("font-size", "14px")
        .style("pointer-events", "none")
        .style("opacity", 0)
    }

    svg
      .selectAll("rect")
      .on("mouseover", (event, d) => {
        tooltip.transition().style("opacity", 0)

        tooltip
          .html(
            `Mood: ${d.mood.mood_name}<br/>Intensity: ${Math.floor(
              d.mood.intensity * 10,
              0,
            )}<br/>Date: ${formatDate(d.date)}<br/>Time: ${getLocalTime(d.mood.created_at, d.mood.completed_tz)}`,
          )
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 20}px`)
          .transition()
          .style("opacity", 1)
      })
      .on("mousemove", (event) => {
        tooltip.style("left", `${event.pageX + 10}px`).style("top", `${event.pageY - 20}px`)
      })
      .on("mouseout", () => {
        tooltip.transition().style("opacity", 0)
      })
  }
}

export default MoodStackGraphD3
