import { useEffect, useRef } from "react"

import useResizeWindow from "@features/Graphs/hooks/useResizeWindow.ts"

import useMoodInsightStore from "../../components/mood/store/InsightReportMoodStore.ts"
import DensityGraphD3Animated from "./D3Files/DensityGraphAnimatedD3.js"

interface DensityGraphProps {
  startFromIndex?: number
  enableAutoPlay?: boolean
}

const DensityGraph = ({ startFromIndex = 0, enableAutoPlay = false }: DensityGraphProps) => {
  const chartArea = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerSize = useResizeWindow(containerRef)
  const isValidSize = containerSize.width > 0 && containerSize.height > 0
  const currentGraphData = useMoodInsightStore((state) => state.currentMoodResponse)
  const validData = currentGraphData
  const transitionDuration = 3000

  useEffect(() => {
    if (isValidSize && validData) {
      new DensityGraphD3Animated(
        chartArea.current,
        containerSize.width,
        containerSize.height,
        currentGraphData,
        transitionDuration,
        enableAutoPlay,
        startFromIndex,
      )
    }
  }, [containerSize, currentGraphData, isValidSize, validData, transitionDuration, enableAutoPlay, startFromIndex])

  return (
    <div className="w-full h-full overflow-hidden" ref={containerRef}>
      <div className="chart-area flex-1 w-full h-full overflow-hidden" ref={chartArea}></div>
    </div>
  )
}

export default DensityGraph
