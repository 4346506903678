import Card from "@/components/Cards/Card.tsx"

import SectionContainer from "../atom/SectionContainer"
import MoodInsightChart from "./MoodInsightChart"

const SleepSection: React.FC = () => {
  const cardStyle = "border w-full shadow-none"

  return (
    <SectionContainer
      title="Mood Chart"
      description="Mood entries from the past 3 weeks (displayed in the user's local timezone)"
    >
      <div className="flex gap-3">
        <Card additionalClasses={cardStyle}>
          <MoodInsightChart />
        </Card>
      </div>
    </SectionContainer>
  )
}

export default SleepSection
