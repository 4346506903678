import Card from "@/components/Cards/Card.tsx"
import LoadingSpinner from "@/components/Loading/LoadingSpinner"

import TrendIcon from "../atom/TrendIcon"

interface ActivityGraphCardLoadingProps {
  label: string
  value?: string | number | undefined
  unit?: string
  trend?: "up" | "down" | "none"
  isError?: boolean
  errorMesssage?: string
}
const ActivityGraphCardLoading: React.FC<ActivityGraphCardLoadingProps> = ({
  label,
  value = "-",
  unit = "-",
  trend = "none",
  isError = false,
  errorMesssage = "Unable to Load Data",
}) => {
  const valueToDisplay = value + unit
  return (
    <Card additionalClasses="border w-full shadow-none" padding="p-2">
      <div className="w-full flex flex-col items-center mt-4 mb-2">
        <div className="flex">
          <div className="text-sm text-neutral-300 mb-2">{label}</div>
          <TrendIcon trend={trend} additionalClasses="ml-2" useNeutralColor={true} />
        </div>

        <div className="flex">
          <span className="text-3xl">{valueToDisplay} </span>
        </div>
        <div className="h-20">
          {!isError ? (
            <div className="h-full w-full">
              <LoadingSpinner size="2x" />
            </div>
          ) : (
            <div className="h-full w-full text-sm mt-4 text-neutral-200">{errorMesssage}</div>
          )}
        </div>
      </div>
    </Card>
  )
}

export default ActivityGraphCardLoading
